import axios from "axios";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Button from "@ui/Button";
import CustomDatePickerHeader from "@ui/DatepickerCustomHeader";
import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";

import {
  actTypeNameMap,
  axiosErrorMessages,
  labels,
  toastOptions,
  toastOptionsError,
} from "@constants";

import DivorceInformation from "@pages/new-act/step-1/DivorceInformation";

import {
  setLastReachedStep,
  setValidatedData,
} from "@reducers/actCreationSlice";
import {
  selectActData,
  setActData,
  setFormikData,
  setModalData,
} from "@reducers/dataTransferSlice";
import { showModal } from "@reducers/modalsSlice";

import { ActStepProps, PathMap, newActData } from "@types";

import { formatLabel, getDateAfterPeriod, getPathMap } from "@utils";

const Information = ({ setFormikState }: ActStepProps) => {
  const location = useLocation();
  const { actType, publicId } = useParams();
  const isDivorce = "divorce" === actType;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const duplicateTitle: string | null = urlParams.get("duplicate");
  const todayPlusSixMonths = getDateAfterPeriod(new Date(), 6);
  const pathMap: PathMap = getPathMap(actType, publicId);
  const secondStepPath = pathMap[2]?.url || "";
  const actData = useSelector(selectActData);
  const [databaseActData, setDatabaseActData] = useState<newActData | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);

  const actTypeMap: Record<string, string> = {
    birth: "natif",
    convention: "econvention",
    divorce: "dcm",
    digital: "numerise",
  };

  useEffect(() => {
    // If an ID is stored this means we've previously created an act
    if (!publicId) {
      setInitializing(false);
      return;
    }

    axios
      .get(`/api/v1/signbooks/${publicId}`)
      .then((response) => {
        const responseReadAllChecked =
          "true" ===
            response.data.metaDataValues.find(
              (item: any) => item.code === "mustReadAllDocs",
            )?.value || false;

        const dcmIntroAccepted =
          response.data.metaDataValues.find(
            (item: any) => item.code === "dcmIntroAccepted",
          )?.value || false;

        // If the act type is different from the one we're on, we redirect to the correct one
        const normalizedType =
          "" !== response.data.businessType
            ? actTypeNameMap[response.data.businessType]
            : actTypeNameMap[response.data.type];

        if (normalizedType !== actType) {
          // Using navigate() doesn't refresh the component so the useEffect doesn't run again
          document.location = `/acts/${normalizedType}/${publicId}/information`;
          return;
        }

        const responseStep = response.data.metaDataValues.find(
          (item: any) => item.code === "wfStep",
        )?.value;

        dispatch(setLastReachedStep(responseStep));

        setDatabaseActData({
          id: response.data.id,
          name: response.data.name,
          description: response.data.description,
          expirationDate: new Date(response.data.expirationDate),
          readAllChecked: isDivorce || responseReadAllChecked,
        });

        setInitializing(false);

        if (dcmIntroAccepted) {
          dispatch(
            setActData({
              actionConfirmed: dcmIntroAccepted,
            }),
          );

          return;
        }
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialValues = () => {
    if (databaseActData) {
      return {
        name: databaseActData.name || "",
        description: databaseActData.description || "",
        expirationDate: databaseActData.expirationDate || "",
        readAllChecked: isDivorce || databaseActData.readAllChecked,
      };
    }

    return {
      name: duplicateTitle ? `${duplicateTitle} (${labels.copy})` : "",
      description: "",
      expirationDate: todayPlusSixMonths,
      readAllChecked: isDivorce,
    };
  };

  const InformationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9 ]*$/, labels.specialCharatersNotAllowed)
      .min(3, formatLabel(labels.minCharLabel, "3"))
      .max(50, formatLabel(labels.maxCharLabel, "50"))
      .trim(formatLabel(labels.requiredField, labels.newActName))
      .required(formatLabel(labels.requiredField, labels.newActName)),
    description: Yup.string()
      .matches(/^[a-zA-Z0-9 ]*$/, labels.specialCharatersNotAllowed)
      .max(500, formatLabel(labels.maxCharLabel, "500")),
    expirationDate: Yup.date()
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)),
        formatLabel(labels.dateMustBeAfterCurrentDate),
      )
      .required(formatLabel(labels.requiredField, labels.expirationDateOfAct)),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(() => getInitialValues(), [databaseActData]);

  const handleFormSubmit = (values: any) => {
    const formValues: Record<string, any> = {
      name: values.name,
      description: values.description,
      expirationDate: values.expirationDate,
      type: actType && actTypeMap[actType],
      mustReadAllDocumentPages: values.readAllChecked,
      // if act type is divorce add property actionConfirmed to the formValues if not do not add any additional properties
      ...(actType === "divorce" && {
        dcmIntroAccepted: actData.actionConfirmed,
      }),
    };

    // The code below determines whether we need to make a PUT or POST request
    // but in both cases we need to display the loading indicator
    setLoading(true);

    // PUT request for updating an act
    if (databaseActData) {
      const actDataPopulated = [
        "id",
        "name",
        "description",
        "expirationDate",
        "readAllChecked",
      ].every((key) => {
        return databaseActData.hasOwnProperty(key);
      });

      if (actDataPopulated) {
        const fieldsToCompare = [
          "name",
          "description",
          "expirationDate",
          "readAllChecked",
        ];
        const putData: Record<string, string | Date | Object> = {};

        fieldsToCompare.forEach((field) => {
          let valueToCompare;
          let fieldName = field;

          // Explicit name check needed to avoid TypeScript errors
          if ("name" === field || "description" === field) {
            valueToCompare = databaseActData[field];
          }

          if ("expirationDate" === field) {
            valueToCompare = new Date(databaseActData[field]);
          }

          if ("readAllChecked" === field) {
            valueToCompare = databaseActData.readAllChecked;
            fieldName = "mustReadAllDocumentPages";
          }

          if (valueToCompare?.toString() !== formValues[fieldName].toString()) {
            putData[fieldName] = formValues[fieldName];
          }
        });

        if (Object.keys(putData).length === 0) {
          navigate(`/acts/${actType}${secondStepPath}`);
          return;
        }

        return axios
          .put(`/api/v1/signbooks/${publicId}`, {
            // We'll either bring that back or remove all the relevant
            // code if we decide that we'll only send the updated
            // values instead of all values
            // ...putData,
            ...formValues,
          })
          .then(() => {
            setLoading(false);
            toast.success(labels.documentSuccesfullyUpdated, toastOptions);

            navigate(`/acts/${actType}${secondStepPath}`);
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.data.length > 0) {
              error.response.data.forEach((error: any) => {
                toast.error(error.message, toastOptionsError);
              });
              return;
            }

            const errorMessage =
              axiosErrorMessages[error.message] || error.message;
            toast.error(errorMessage, toastOptionsError);
          });
      }

      return;
    }

    // POST request for creating a new
    // act ID in stored on success
    axios
      .post("/api/v1/signbooks", formValues)
      .then((response) => {
        // Add publicId to redux store for use in step 2 imported-parties APi until we have the fix for ID use in the API
        dispatch(
          setActData({
            ...actData,
            publicId: response.data?.publicId,
          }),
        );

        setLoading(false);

        if (!response.data?.publicId) {
          return;
        }

        toast.success(labels.documentSuccesfullyUpdated, toastOptions);

        const redirectPath: PathMap = getPathMap(
          actType,
          response.data?.publicId,
        );

        const secondStepRedirectPath = redirectPath[2].url;

        navigate(`/acts/${actType}${secondStepRedirectPath}`);
      })
      .catch((error) => {
        setLoading(false);
        if (Array.isArray(error.response.data)) {
          error.response.data.forEach((error: any) => {
            toast.error(error.message, toastOptionsError);
          });

          return;
        }

        const errorMessage = axiosErrorMessages[error.message] || error.message;

        toast.error(errorMessage, toastOptionsError);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: InformationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validateOnMount: true,
    initialTouched: {
      description: true,
      expirationDate: true,
    },
    validate: (values) => {
      dispatch(
        setFormikData({
          initial: JSON.stringify(initialValues),
          current: JSON.stringify(values),
        }),
      );

      InformationSchema.validate(values)
        .then(() => {
          dispatch(setValidatedData(true));
        })
        .catch(() => {
          dispatch(setValidatedData(false));
        });
    },
  });

  useEffect(() => {
    setFormikState({
      formik: formik,
      isDirty: formik.dirty,
      callback: () => {
        return handleFormSubmit(formik.values);
      },
      step: 1,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.values]);

  if ("divorce" === actType && !actData?.actionConfirmed) {
    return initializing ? null : <DivorceInformation />;
  }

  const getActTitle = (type: string | undefined) => {
    switch (type) {
      case "divorce":
        return labels.newDivorceActName;
      case "convention":
        return labels.newConventionName;
      case "digital":
        return labels.newDigitalActName;
      default:
        return labels.newActName;
    }
  };

  const newActTitle = getActTitle(actType);

  if (initializing) {
    return (
      <div className="flex justify-center items-center p-6 -mb-2 bg-[#F9F9F9]">
        <Spinner className="size-10 !border-t-black" />
      </div>
    );
  }

  return (
    <div className="px-[45px] py-[40px]">
      <h2 className="headline-lg mb-5">{labels.generalInformationTitle}</h2>
      <p className="body-sm">{labels.completeActInformation}</p>
      <FormikProvider value={formik}>
        <div className="px-[45px] py-[14px] flex flex-col items-center mx-auto">
          <div className="flex flex-col mb-5">
            <label htmlFor="name" className="mb-[8px]">
              {newActTitle}
            </label>
            <Field
              type="text"
              name="name"
              className="px-[10px] py-[3px] border border-[#B4B4B4] rounded-[4px] w-[293px] h-[34px] body-xl"
            />
            <ErrorMessage name="name">
              {(msg) => (
                <span className="text-[10px] max-w-[293px] text-ea-red">
                  {msg}
                </span>
              )}
            </ErrorMessage>
          </div>
          <div className="flex flex-col mb-5">
            <label htmlFor="name" className="mb-[8px]">
              {labels.descriptionOfNewAct}
            </label>
            <Field
              as="textarea"
              name="description"
              className="px-[10px] py-[3px] border border-[#B4B4B4] rounded-[4px] w-[293px] resize-none h-[130px] body-xl"
            />
            <ErrorMessage name="description">
              {(msg) => (
                <span className="text-[10px] max-w-[293px] text-ea-red">
                  {msg}
                </span>
              )}
            </ErrorMessage>
          </div>

          {actType !== "digital" ? (
            <>
              <div className="mt-1 mb-5 w-full max-w-[293px]">
                <label
                  className={`${isDivorce && "disabled text-ea-gray-100"} body-md custom-checkbox-container`}
                >
                  <Field
                    type="checkbox"
                    className="custom-checkbox"
                    name="readAllChecked"
                    disabled={isDivorce}
                  />
                  {labels.obligationToReadAllPages}
                </label>
              </div>

              <div className="max-w-[293px]">
                <label htmlFor="name">{labels.expirationDateOfAct}</label>
                <div className="relative mt-[8px]">
                  <Icon
                    type="calendar"
                    color="#000"
                    className="absolute top-[50%] left-[16px] transform -translate-y-1/2 w-[17px] h-[19px] z-[1]"
                  />

                  <DatePicker
                    renderCustomHeader={(props) => (
                      <CustomDatePickerHeader {...props} />
                    )}
                    className="font-medium text-black pl-[40px] py-[5px] pr-16 bg-white rounded border border-solid border-[#B4B4B4] w-[293px] h-[34px]"
                    selected={formik.values.expirationDate}
                    onChange={(date) =>
                      formik.setFieldValue("expirationDate", date)
                    }
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <ErrorMessage name="expirationDate">
                  {(msg) => (
                    <span className="text-[10px] max-w-[293px] text-ea-red">
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </div>
              <div className="flex max-w-[293px] mt-[10px] mb-[23px]">
                <Icon
                  type="exclamation-triangle"
                  color="#000"
                  className="w-[30px] h-[30px] mr-[5px]"
                />
                <p className="body-sm">{labels.signingDateInformation}</p>
              </div>
            </>
          ) : (
            <div className="mb-[23px]"></div>
          )}

          <div className="flex justify-between w-full">
            <Button
              type="button"
              disabled={loading}
              onClick={() => {
                dispatch(showModal("cancelAct"));
                // Set the form values if the user wants to register the act
                dispatch(
                  setModalData({
                    formValues: {
                      name: formik.values.name,
                      description: formik.values.description,
                      expirationDate: formik.values.expirationDate,
                      type: actType ? actTypeMap[actType] : "",
                      mustReadAllDocumentPages: formik.values.readAllChecked,
                      // if act type is divorce add property actionConfirmed to the formValues if not do not add any additional properties
                      ...(actType === "divorce" && {
                        dcmIntroAccepted: actData.actionConfirmed,
                      }),
                    },
                  }),
                );
              }}
              className="btn-base btn-secondary"
            >
              {labels.cancelTheAct}
            </Button>
            <Button
              type="submit"
              onClick={() => formik.handleSubmit()}
              disabled={!formik.isValid || loading}
              className="btn-base btn-primary flex items-center justify-center"
              data-testid="next"
            >
              {loading && <Spinner className="mr-2 size-4" />}
              {labels.next}
            </Button>
          </div>
        </div>
      </FormikProvider>
    </div>
  );
};

export default Information;
