import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import HistoryFilters from "@components/filters/HistoryFilters";
import PlainTable from "@components/tables/PlainTable";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";
import Pagination from "@ui/Pagination";
import PhoneMessage from "@ui/PhoneMessage";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import useIsMobile from "@hooks/useIsMobile";
import useOrientation from "@hooks/useOrientation";
import useWindowSize from "@hooks/useWindowSize";

import { hideModal } from "@reducers/modalsSlice";
import { setLoading as reduxLoading } from "@reducers/tracesSlice";

import { HistoryFilterProps, InvoiceHistoryTableProps } from "@types";

import {
  invoiceDownloadApi,
  invoiceExportApi,
  invoiceGetHistoryApi,
} from "@utils/api/invoiceHistoryApi";

const InvoiceHistory = () => {
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const isPortrait = useOrientation();
  const isMobile = useIsMobile();
  const windowsWidth = useWindowSize();
  const [tableCells, setTableCells] = useState<InvoiceHistoryTableProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reset, setReset] = useState(false);
  const [options, setOptions] = useState<{ name: string } | undefined>();

  const actTypeMap: Record<string, string> = {
    natif: "birth",
    econvention: "convention",
    dcm: "divorce",
    numerise: "digital",
  };

  const tableHeaders = [
    {
      label: labels.date,
      render: (tableCells: InvoiceHistoryTableProps) => (
        <p>{tableCells.date}</p>
      ),
      className: "body-md py-4",
    },
    {
      label: labels.document,
      render: (tableCells: InvoiceHistoryTableProps) => (
        <p>
          {tableCells.signbookNumber && tableCells.type ? (
            <Link
              to={`/acts/${actTypeMap[tableCells.type]}/act-details/${tableCells.signbookNumber}`}
              className="underline underline-offset-2"
              onClick={() => {
                dispatch(hideModal());
              }}
            >
              {tableCells.document}
            </Link>
          ) : (
            tableCells.document
          )}
        </p>
      ),
      className: "body-md py-4",
    },
    {
      label: labels.amount,
      render: (tableCells: InvoiceHistoryTableProps) => (
        <p>{tableCells.amount}</p>
      ),
      className: "body-md py-4",
    },
    {
      label: "",
      render: (tableCells: InvoiceHistoryTableProps) => (
        <div>{tableCells.action}</div>
      ),
      className: "body-md py-4",
    },
  ];

  const [filters, setFilters] = useState<HistoryFilterProps>({
    startDate: "",
    endDate: "",
  });

  const invoiceHistoryWidth = isMobile ? 620 : 720;
  const invoiceHistoryHeight = isMobile && !isPortrait ? 300 : "";
  const formatedFilter = `${filters.startDate}#${filters.endDate}`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      dispatch(reduxLoading(true));
      try {
        const data = await invoiceGetHistoryApi(page, 10);
        if (!data) {
          return;
        }

        const formattedData = data.results.map((item: any) => ({
          date: new Date(item.creationDate).toLocaleString("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false, // Ensures 24-hour time format
          }),
          document: item.signbookName,
          signbookNumber: item.signbookNumber,
          type: item.signbookType,
          status: item.signbookStatus,
          amount: `${item.amount.toFixed(2)} €`,
          resourcePath: item.invoiceUuid,
          action: (
            <div className="text-center">
              <button
                onClick={() => {
                  invoiceDownloadApi(item.invoiceUuid);
                }}
              >
                <Icon type="download" className="size-4" />
              </button>
            </div>
          ),
        }));
        setTotalPages(data.pages);

        setLoading(false);

        dispatch(reduxLoading(false));
        setTableCells(formattedData);
      } catch (error) {
        const errorMessage =
          (error as any).response?.data?.message || (error as any).message;
        toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      dispatch(reduxLoading(true));
      try {
        const data = await invoiceGetHistoryApi(
          page,
          10,
          formatedFilter,
          options?.name,
        );

        if (!data) {
          return;
        }

        const formattedData = data.results.map((item: any) => ({
          date: new Date(item.creationDate).toLocaleString("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false, // Ensures 24-hour time format
          }),
          document: item.signbookName,
          signbookNumber: item.signbookNumber,
          type: item.signbookType,
          status: item.signbookStatus,
          amount: `${item.amount.toFixed(2)} €`,
          resourcePath: item.invoiceUuid,
          action: (
            <div className="text-center">
              <button
                onClick={() => {
                  invoiceDownloadApi(item.invoiceUuid);
                }}
              >
                <Icon type="download" className="size-4" />
              </button>
            </div>
          ),
        }));
        setTotalPages(data.pages);

        setLoading(false);
        dispatch(reduxLoading(false));
        setTableCells(formattedData);
      } catch (error) {
        const errorMessage =
          (error as any).response?.data?.message || (error as any).message;
        toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, options, page]);

  return (
    <Modal
      title={labels.invoicesHistory}
      width={invoiceHistoryWidth}
      height={invoiceHistoryHeight}
    >
      <div className="mt-[25px]">
        <PhoneMessage />
      </div>
      <div className="flex justify-end">
        <button
          className="flex btn-secondary px-5 py-1 text-sm rounded-lg"
          onClick={async () => {
            setDownloading(true);
            await invoiceExportApi(page, 10, formatedFilter, options?.name);
            setDownloading(false);
          }}
          disabled={loading || downloading}
        >
          {downloading && <Spinner className="size-4 mr-4" />}
          {labels.downloadInvoices}
        </button>
      </div>
      <div className="flex justify-between w-full my-[18px]">
        <Formik
          enableReinitialize
          initialValues={{
            name: options?.name || "", // Assuming title is the value you want to search for
          }}
          onSubmit={(values) => {
            setOptions({
              name: values.name, // Update the name based on the form value
            });
          }}
        >
          {({ values, handleChange, resetForm }) => (
            <Form>
              <div className="flex items-center gap-2.5">
                <div className="w-full md:w-auto flex flex-col">
                  <div className="relative">
                    <Icon
                      type="search"
                      color="black"
                      className="absolute w-[16px] h-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                    />
                    <input
                      type="text"
                      name="name" // Connect to Formik's `name` field
                      value={values.name} // Bind to Formik's state
                      onChange={handleChange} // Ensure input is tracked by Formik
                      className="flex pl-[47px] pr-[10px] py-2 w-[280px] text-xs bg-white rounded border border-solid border-neutral-300 text-neutral-400"
                      placeholder={labels.invoiceHistoryTitleSearch}
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <Button
                    onClick={() => {
                      resetForm();
                      setReset(true);
                      setOptions({
                        ...options,
                        name: "",
                      });
                    }}
                    type="button"
                    disabled={loading}
                  >
                    <Icon
                      type="refresh"
                      color="#7F7F7F"
                      className="w-[16px] h-[16px] mr-[16px]"
                    />
                  </Button>
                  <Button
                    type="submit"
                    className="btn-base btn-secondary flex items-center justify-center"
                    disabled={loading}
                  >
                    {loading && <Spinner className="size-4 mr-4" />}
                    {labels.search}
                  </Button>
                  <button
                    type="button"
                    className="flex items-center my-auto text-sm ml-4"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowFilters(!showFilters);
                    }}
                  >
                    <Icon
                      type="filters"
                      color="#000"
                      className="w-[18px] h-[18px] mr-[12px]"
                    />
                    {windowsWidth === 844 ? "" : labels.filters}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {showFilters && (
        <HistoryFilters
          setShowFilters={setShowFilters}
          setFilters={setFilters}
          setReset={setReset}
        />
      )}
      <div className="relative right-[-42px] z-10 mb-10">
        <Pagination page={page} totalPages={totalPages} setPage={setPage} />
      </div>
      <PlainTable
        columns={tableHeaders}
        rows={tableCells}
        tableType="invoiceHistory"
      />
    </Modal>
  );
};

export default InvoiceHistory;
