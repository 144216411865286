import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import FieldDropdown from "@ui/FieldDropdown";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import {
  wayOfPaymentOptions as basePaymentOptions,
  labels,
  toastOptions,
} from "@constants";

import {
  selectModalData,
  selectPageData,
  setModalData,
  setPageData,
  setPostState,
} from "@reducers/dataTransferSlice";
import { selectPrepaidAccountInfo, selectUser } from "@reducers/metadataSlice";
import { hideModal } from "@reducers/modalsSlice";

import { formatLabel } from "@utils";
import {
  paymentWithBankCard,
  paymentWithPrepaidAccount,
} from "@utils/api/paymentApi";

const Payment = () => {
  const [facturation, setFacturation] = useState<
    { value: string; label: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const userData = useSelector(selectUser);
  const dispatch = useDispatch();
  const prepaidAccountInfo = useSelector(selectPrepaidAccountInfo);
  const balanceAmount = prepaidAccountInfo?.balance;
  const data = useSelector(selectModalData);
  const pageData = useSelector(selectPageData);

  useEffect(() => {
    const fullName = `${userData.firstName} ${userData.lastName}`;

    // Find the metadata with the code 'companyName'
    const companyMetaData = userData.metaDataValues.find(
      (meta) => meta.code === "companyName",
    );

    const facturationOptions = [
      { value: fullName, label: fullName },
      {
        value: companyMetaData?.value || "",
        label: companyMetaData?.value || "",
      },
    ];

    setFacturation(facturationOptions);
  }, [data, userData]);

  // Update the note for prepaidAccount
  const wayOfPaymentOptions = basePaymentOptions.map((option) => {
    if (option.value === "prepaidAccount") {
      return {
        ...option,
        note: `${balanceAmount} ${labels.euroSign}`, // Use balanceAmount dynamically
      };
    }
    return option;
  });

  const initialValues = {
    billing: facturation[0]?.value || "",
    paymentMethod: "",
  };
  if (facturation.length === 0) return null;

  return (
    <Modal title={labels.payment} width={400}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          let updatedData = {
            ...pageData,
            ...data,
            ...values,
          };

          if (values.paymentMethod === "prepaidAccount") {
            setLoading(true);
            const response = await paymentWithPrepaidAccount(
              data.publicId,
              values.billing,
            );
            if (response === 200) {
              toast.success(labels.paymentSuccess, toastOptions);
              setLoading(false);
              dispatch(setPostState(true));
            }
          } else {
            paymentWithBankCard(data.publicId, values.billing);
          }

          dispatch(setPageData(updatedData));
          dispatch(setModalData(null));
          dispatch(hideModal());
        }}
      >
        {(formik) => {
          return (
            <Form>
              <h3 className="mb-2 mt-5">{labels.actReference}</h3>
              <p className="mb-6 body-lg">{data.title || data.name}</p>

              <div className="mb-6">
                <label htmlFor="billing" className="body-lg mb-[8px]">
                  {labels.billing}
                </label>
                <Field name="billing" type="hidden" />

                <FieldDropdown
                  arrowPosition="right"
                  options={facturation}
                  initialValue={facturation[0]?.value}
                  fontSize="body-lg"
                  inputHeight="h-[38px]"
                  paddingLeft="pl-[10px]"
                  marginTop="mt-2"
                  onChange={(value) => {
                    formik.setFieldValue("billing", value);
                  }}
                />
              </div>

              <div className="mb-6">
                <label className="body-lg mb-[8px]" htmlFor="paymentMethod">
                  {labels.wayOfPayment}
                </label>
                <Field name="paymentMethod" type="hidden" />
                <FieldDropdown
                  arrowPosition="right"
                  options={wayOfPaymentOptions}
                  initialValue={wayOfPaymentOptions[0].value}
                  fontSize="body-lg"
                  inputHeight="h-[38px]"
                  paddingLeft="pl-[10px]"
                  marginTop="mt-2"
                  onChange={(value) => {
                    formik.setFieldValue("paymentMethod", value);
                  }}
                />
              </div>

              <p className="body-sm text-ea-gray-300 mb-4">
                {formatLabel(
                  labels.invoiceOfPayment,
                  facturation.find(
                    (option) => option.value === formik.values.billing,
                  )?.label || "",
                )}
              </p>

              <Button
                className="btn-primary px-3 py-2 rounded-[8px] body-md flex mr-0 ml-auto items-center"
                type="submit"
                disabled={loading}
              >
                {loading && <Spinner className="size-4 mr-4" />}
                {formatLabel(labels.payAmount, data.amount || data.actAmount)}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Payment;
