import { Field, useFormikContext } from "formik";
import { useEffect, useState } from "react";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import { countries } from "@constants/region";

import { CountryFieldProps } from "@types";

const CountryField = ({
  nationality = "countryNationalityCode",
  setFieldValue,
  invertedDropdown = false,
  disabled = false,
}: CountryFieldProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { getFieldProps } = useFormikContext();
  const countryCodeValue = getFieldProps(nationality).value;

  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (!countryCodeValue) {
      return;
    }
    setFilter(countries[countryCodeValue]);
  }, [countryCodeValue]);

  const dropdownPositionClass = invertedDropdown ? "bottom-full" : "top-full";

  const disabledField = disabled
    ? "border-[#F5F5F5] bg-white"
    : "border-[#B4B4B4]";

  // Set default to "France" on mount
  useEffect(() => {
    if (!countryCodeValue) {
      setFieldValue(nationality, "FR");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CountriesDropdown = () => {
    const filteredCountries = Object.entries(countries).filter(([code, name]) =>
      name?.toLowerCase().includes(filter?.toLowerCase()),
    );

    useEffect(() => {
      const hideDropdown = () => {
        if (showDropdown) {
          setShowDropdown(false);
        }
      };

      window.addEventListener("click", hideDropdown);

      return () => {
        window.removeEventListener("click", hideDropdown);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDropdown]);

    if (!showDropdown) {
      return null;
    }

    return (
      <div
        id="country-select-dropdown"
        className={`country-select-dropdown ${dropdownPositionClass}`}
        data-testid="country-select-dropdown"
      >
        <ul className="py-2 text-sm">
          {filteredCountries.map(([code, name], index) => (
            <li key={index}>
              <Button
                type="button"
                className="inline-flex w-full px-4 py-2 text-sm body-sm"
                role="menuitem"
                onClick={() => {
                  setFieldValue(nationality, code);
                  setFilter(name); // Update the input field to show the selected country's name
                  setShowDropdown(false);
                }}
              >
                <div className="inline-flex items-center">{name}</div>
              </Button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="relative w-full body-lg">
      <div
        className={`self-stretch border ${disabledField} h-[34px] py-1 px-4 rounded-[4px]`}
      >
        <Field as="select" className="hidden" name={nationality}>
          {Object.entries(countries).map(([code, name], index) => (
            <option key={index} value={code}>
              {name}
            </option>
          ))}
        </Field>
        <input
          type="text"
          value={filter}
          disabled={disabled}
          onClick={(event) => {
            if (disabled) return;
            event.stopPropagation();
            setShowDropdown(!showDropdown);
          }}
          onChange={(event) => {
            const value = event.target.value;
            setFilter(value); // Update filter to typed value
            if (!value) {
              setFieldValue(nationality, ""); // Clear Formik value when input is empty
            }
          }}
          className={`w-full border-none outline-none bg-transparent ${
            disabled ? "bg-gray-200 cursor-not-allowed" : ""
          }`}
        />
        {disabled ? null : (
          <Icon
            type="dropdown-arrow"
            className="size-[12px] absolute top-1/2 right-[16px] -translate-y-2/4 pointer-events-none"
            color="#000"
          />
        )}
        <CountriesDropdown />
      </div>
    </div>
  );
};

export default CountryField;
