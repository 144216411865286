import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, toastOptionsError } from "@constants";

const getPrepaidAccountInfo = (accessToken: string | undefined) => {
  return axios
    .get(`api/v1/prepaid-account/data`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
      }
    });
};

const updatePrepaidAccount = (
  accessToken: string | undefined,
  amount: string,
  userName: string,
) => {
  return axios
    .get(
      `/api/v1/prepaid-account/reload-transaction?amount=${amount}&paymentBuyer=${userName}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .then((response) => {
      // Check if the sessionUrl exists in the response
      if (response.data && response.data.sessionUrl) {
        return response.data;
      }
    })
    .catch((error) => {
      const errorMessage = axiosErrorMessages[error.message] || error.message;
      toast.error(errorMessage, toastOptionsError);
      return undefined; // Return undefined to signal the error
    });
};

const updateAccountAlertThreshold = (
  balanceAmount: number,
  accountLimitWarning: boolean,
) => {
  axios
    .patch(`/api/v1/prepaid-account/data`, {
      balance: Math.round(balanceAmount * 100),
      currentEstimatedBalance: Math.round(balanceAmount * 100),
      warningNotificationLimit: 3000,
      warningNotificationLimitType: accountLimitWarning ? "ABSOLUTE" : "NONE",
    })
    .then(() => {})
    .catch((error) => {
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    });
};

export {
  getPrepaidAccountInfo,
  updatePrepaidAccount,
  updateAccountAlertThreshold,
};
