import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, toastOptionsError } from "@constants";

const documentToPrint = async (
  processNumber: string,
  actorCode: string,
  signBookNumber: string,
  accessToken: string | null,
  documents: any[],
  isClosed?: boolean,
): Promise<string[]> => {
  try {
    const fileUrls = await Promise.all(
      documents.map(async (document) => {
        let requestUrl = `api/v1/signbooks/${signBookNumber}/documents/${document.documentUid}/data`;
        let requestOptions: any = {
          responseType: "arraybuffer",
          headers: {} as any,
        };

        // Will be updated later when `main-documents` is fixed for logged in user
        if (accessToken !== "") {
          requestUrl = `/shared-api/v1/process/${processNumber}/actors/${actorCode}/signbooks/${signBookNumber}/main-documents`;
          requestOptions.headers.Authorization = `Bearer ${accessToken}`;
        }

        if (isClosed) {
          requestUrl = `api/v1/signbooks/${signBookNumber}/main-documents/${document.documentUid}`;
        }

        let response;
        if (accessToken !== "") {
          response = await axios.post(requestUrl, document, requestOptions);
        } else {
          response = await axios.get(requestUrl, requestOptions);
        }

        const contentType = response.headers["content-type"];
        const fileBlob = new Blob([response.data], { type: contentType });
        return URL.createObjectURL(fileBlob);
      }),
    );

    return fileUrls; // Returns all the file URLs
  } catch (error: any) {
    toast.error(
      axiosErrorMessages[error.message] || "An unexpected error occurred",
      toastOptionsError,
    );
    throw error; // Rethrow the error if needed
  }
};

export { documentToPrint };
