import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Button from "@ui/Button";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import {
  selectModalData,
  setLoadingState,
  setPostState,
} from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

import {
  extractFileName,
  fetchAndDownloadTemplate,
  removeBaseUrl,
} from "@utils";

const DivorceConfirmation = () => {
  const { "*": rest } = useParams();
  const publicId = rest ? rest.split("/")[0] : "";
  const data = useSelector(selectModalData);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    confirmation: false,
    readAll: false,
  };

  const validationSchema = Yup.object().shape({
    confirmation: Yup.boolean().oneOf([true]),
    readAll: Yup.boolean().oneOf([true]),
  });

  const dispatch = useDispatch();

  const handleDivorceConfirmation = () => {
    setLoading(true);
    dispatch(setLoadingState(true));
    axios
      .post(`/api/v1/signbooks/${publicId}/send`, {
        order: data.order,
      })
      .then(() => {
        axios
          .get(`/api/v1/signbooks/${publicId}/signings/order`)
          .then((response) => {
            const orderData = response.data;
            document.location.href = removeBaseUrl(orderData.redirectURI);
          })
          .catch((error) => {
            setLoading(false);
            toast.error(axiosErrorMessages[error.message], toastOptionsError);
          })
          .finally(() => {
            dispatch(setLoadingState(false));
          });
      })
      .catch((error) => {
        setLoading(false);
        dispatch(setPostState(false));

        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  const handleDownloadFinalAct = () => {
    axios
      .get(`/api/v1/signbooks/${publicId}/documents/data`, {
        responseType: "blob",
      })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        const filename = extractFileName(contentDisposition);
        const data = response.data;
        const blob = new Blob([data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  return (
    <Modal title={labels.confirmation} width={640}>
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {
          handleDivorceConfirmation();
        }}
      >
        {(formik) => (
          <Form>
            <div className="modal-content">
              {labels.priceNotice}
              <h2>{labels.firstPointValidationOfAct}</h2>
              <p>{labels.creationOfFinalAct}</p>
              <p>{labels.modificationImpossible}</p>
              <p>
                <a
                  href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    handleDownloadFinalAct();
                  }}
                >
                  {labels.downloadFinalAct}
                </a>
              </p>
              <div className="custom-checkbox-container custom-checkbox-container-multiline">
                <label>
                  <Field
                    name="confirmation"
                    type="checkbox"
                    className="custom-checkbox"
                  />
                  {labels.confirmationCheckboxDivorceFinalDocument}
                </label>
              </div>
              <h2>{labels.secondPointSummary}</h2>
              <p>
                {labels.click}
                <a
                  href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    fetchAndDownloadTemplate("ENROLLMENT_CGU_UID");
                  }}
                >
                  {labels.here}
                </a>
                {labels.forOpeningGeneralConditions}
              </p>
              <div className="custom-checkbox-container custom-checkbox-container-multiline">
                <label>
                  <Field
                    name="readAll"
                    type="checkbox"
                    className="custom-checkbox"
                  />
                  {labels.divorceReadConditionsCheckbox}
                </label>
              </div>
              <p>{labels.divorceArchiveNotice}</p>
            </div>
            <div className="flex justify-between items-center">
              <Button
                className="btn-base btn-secondary"
                type="button"
                disabled={loading}
                onClick={() => {
                  dispatch(hideModal());
                }}
              >
                {labels.cancel}
              </Button>
              <Button
                className="btn-base btn-primary flex items-center justify-center"
                type="submit"
                disabled={!formik.isValid || loading}
              >
                {loading && <Spinner className="mr-2 size-4" />}
                {labels.continue}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DivorceConfirmation;
