import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Signature from "@components/fragments/profile/Signature";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";

import { labels } from "@constants";

import { fetchAndDownloadTemplate, formatLabel, validateEmail } from "@utils";
import { getLoggedUser, updateCGU, updateUser } from "@utils/api/userInfoApi";

type Values = {
  surname: string;
  name: string;
  email: string;
  termConditionState: boolean;
};

const YourProfile = () => {
  const [termConditionState, setTermConditionState] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      const response = await getLoggedUser();

      setLoading(false);
      setUserData(response);
    };
    fetchUser();
  }, []);

  const initialValues = {
    surname: userData.lastname,
    name: userData.firstname,
    email: userData.email,
    termConditionState: termConditionState,
  };

  const ProfileScheme = Yup.object().shape({
    email: Yup.string()
      .email(formatLabel(labels.compliantField, labels.email))
      .required(formatLabel(labels.requiredField, labels.email)),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={ProfileScheme}
      onSubmit={async (values: Values) => {
        const updatedUser = {
          ...userData,
          email: values.email,
        };
        const updateResponse = await updateUser(updatedUser);

        if (updateResponse === 200) {
          const cguResponse = await updateCGU();
          if (cguResponse) {
            navigate(`/onboarding/preferred-acts`);
          }
        }
      }}
    >
      {() => (
        <Form>
          <div className="flex flex-col px-[45px] py-[40px] mt-10 justify-center items-center">
            <h2 className="flex headline-lg mb-5 text-center">
              {labels.yourProfile}
            </h2>
            <p className="body-lg w-[440px] text-center">
              {labels.YourProfilePageDescription}
            </p>
            <div className="flex-column mt-5">
              <div>
                <p className="my-2">
                  <label className="body-sm text-[#8F8F8F]" htmlFor="surname">
                    {labels.surname}
                  </label>
                </p>
                <Field
                  id="surname"
                  name="surname"
                  type="text"
                  disabled
                  className="body-lg border border-[#F5F5F5] bg-white py-[4px] px-[16px] rounded-[4px] w-full lg:w-[293px]"
                />
                <Icon
                  type="check"
                  className="relative top-1/2 -translate-y-[23px] left-[268px] size-[14px]"
                  color="#00C45A"
                />
              </div>
              <div>
                <p className="my-2">
                  <label className="body-sm text-[#8F8F8F]" htmlFor="name">
                    {labels.name}
                  </label>
                </p>
                <Field
                  id="name"
                  name="name"
                  type="text"
                  disabled
                  className="body-lg border border-[#F5F5F5] bg-white py-[4px] px-[16px] rounded-[4px] w-full lg:w-[293px]"
                />
                <Icon
                  type="check"
                  className="relative top-1/2 -translate-y-[23px] left-[268px] size-[14px]"
                  color="#00C45A"
                />
              </div>
              <div className="flex flex-col w-full ">
                <p className="my-2">
                  <label className="body-sm text-[#8F8F8F]" htmlFor="email">
                    {labels.email}
                  </label>
                </p>
                <Field
                  id="email"
                  name="email"
                  type="text"
                  className="body-lg border border-[#B4B4B4] py-[4px] px-[16px] rounded-[4px] w-full lg:w-[293px]"
                  validate={validateEmail}
                />
                <ErrorMessage name="email">
                  {(msg) => (
                    <span className="text-[10px] max-w-[293px] text-ea-red">
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </div>
            </div>
            <div className="body-xl mt-[34px] lg:mt-5">
              <p className="pb-3">{labels.myHandwrittenSignature}</p>
              <Signature />
            </div>
            <p className="body-md w-[440px] mt-10 text-ea-gray-200 text-center">
              {labels.yourProfileInfoText}
            </p>
            <div className="flex justify-center body-md mt-14">
              <label className="relative your-profile">
                <input
                  type="checkbox"
                  name="checkbox"
                  checked={termConditionState}
                  onChange={() => {
                    setTermConditionState(!termConditionState);
                  }}
                  className={`${
                    termConditionState
                      ? "custom-checkbox-checked"
                      : "your-profile custom-checkbox"
                  }`}
                />
                {labels.accept}
                <Button
                  type="button"
                  className="underline cursor-pointer ml-1"
                  onClick={(e) => {
                    e.preventDefault();
                    fetchAndDownloadTemplate("ENROLLMENT_CGU_UID");
                  }}
                >
                  {labels.termsAndConditions}
                </Button>
              </label>
            </div>
            <div className="flex justify-end w-full mt-20">
              <Button
                type="submit"
                className="btn-base btn-primary flex items-center justify-center w-[132px]"
                data-testid="next"
                disabled={!termConditionState || loading}
              >
                {loading && <Spinner className="mr-2 size-4" />}
                {labels.next}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default YourProfile;
