import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, toastOptionsError } from "@constants";

import { addQueryParams, extractFileName } from "@utils";

const invoiceGetHistoryApi = (
  page: number,
  size: number,
  dateFilter?: string,
  nameFilter?: string,
) => {
  return axios
    .get(
      addQueryParams(`/api/v1/invoice/history?`, {
        page: (page - 1).toString(),
        size: size.toString(),
        sortCol: "CREATION_DATE",
        sortDir: "desc",
        creationDate: dateFilter || "",
        name: nameFilter || "",
      }),
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
    });
};

const invoiceDownloadApi = (resourcePath: string) => {
  return axios
    .get(`/api/v1/invoice/${resourcePath}/download`, {
      responseType: "blob",
    })
    .then((response) => {
      const contentDisposition = response.headers["content-disposition"];
      const filename = extractFileName(contentDisposition);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
    });
};

const invoiceExportApi = async (
  page: number,
  size: number,
  dateFilter?: string,
  nameFilter?: string,
) => {
  const params = [];
  if (dateFilter) {
    params.push(`creationDate=${encodeURIComponent(dateFilter)}`);
  }
  if (nameFilter) {
    params.push(`name=${nameFilter}`);
  }
  const queryString = params.length > 0 ? `&${params.join("&")}` : "";

  return axios
    .get(`/api/v1/invoice/export?page=${page - 1}&size=${size}${queryString}`, {
      responseType: "blob",
    })
    .then((response) => {
      const contentDisposition = response.headers["content-disposition"];
      const filename = extractFileName(contentDisposition);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
    });
};

const getInvoiceBySignBookApi = (signBookName: string) => {
  return axios
    .get(`/api/v1/invoice/signbook/${signBookName}/download`, {
      responseType: "blob",
    })
    .then((response) => {
      const contentDisposition = response.headers["content-disposition"];
      const filename = extractFileName(contentDisposition);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
    });
};

export {
  invoiceExportApi,
  invoiceGetHistoryApi,
  invoiceDownloadApi,
  getInvoiceBySignBookApi,
};
