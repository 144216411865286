import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";

import Button from "@ui/Button";
import CustomDatePickerHeader from "@ui/DatepickerCustomHeader";
import Icon from "@ui/Icon";
import Multiselect from "@ui/MultiSelect";

import { DAY_IN_MILLISECONDS, dropdownOptions, labels } from "@constants";

import { ParamsContext } from "@pages/Documents";

import { selectPostState } from "@reducers/dataTransferSlice";

import { FiltersProps, MultiselectOption } from "@types";

import {
  formatTimestampForRequest,
  isStartDateBeforeEndDate,
  noop,
  setDates,
} from "@utils";

const Filters = ({
  setShowFilters,
  setFilters,
  activeTabUrl,
}: FiltersProps) => {
  const params = useContext(ParamsContext);
  const paymentState = useSelector(selectPostState);
  const [activeTab, setActiveTab] = useState(0);
  const [selected, setSelected] =
    useState<MultiselectOption[]>(dropdownOptions);
  const [startDate, setStartDate] = useState<Date | string>("");
  const [endDate, setEndDate] = useState<Date | string>("");
  const [partyName, setPartyName] = useState<string>("");
  const [lawyerName, setLawyerName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");
  const [state, setState] = useState<string[]>([]);

  useEffect(() => {
    if (selected.length === dropdownOptions.length) {
      setState([]);

      return;
    }

    setState(selected.map((status) => status.value) as string[]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const fontClass = (id: number) => {
    return activeTab === id ? "font-bold" : "font-light";
  };

  const resetFilters = (formik: any) => {
    setStartDate("");
    formik.setFieldValue("startDate", "");
    setEndDate("");
    formik.setFieldValue("endDate", "");
    setTitle("");
    formik.setFieldValue("title", "");
    setNumber("");
    formik.setFieldValue("number", "");
    setSelected(dropdownOptions);
  };

  const resetInputs = () => {
    setPartyName("");
    setLawyerName("");
    setKeyword("");
  };

  const handleSearch = () => {
    if (setFilters) {
      setFilters({
        startDate,
        endDate,
        selected,
        partyName,
        lawyerName,
        keyword,
      });
    }

    if (0 !== Object.keys(params).length) {
      let creationDateMin = "";
      let creationDateMax = "";

      if ("object" === typeof startDate && "object" === typeof endDate) {
        creationDateMin = formatTimestampForRequest(
          (startDate as Date)?.getTime(),
        );

        creationDateMax = formatTimestampForRequest(
          (endDate as Date)?.getTime() + DAY_IN_MILLISECONDS,
        );
      }

      params.setRequestParams({
        ...params.requestParams,
        party: partyName,
        depositor: lawyerName,
        thirdLawyer: lawyerName,
        keyword: keyword,
        creationDateMin: creationDateMin,
        creationDateMax: creationDateMax,
        name: title,
        publicId: number,
        steps: state,
        page: 1,
      });
    }
  };

  useEffect(() => {
    if (!paymentState) {
      return;
    }
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentState]);

  const onChange = (dates: [Date | string, Date | string], formik: any) => {
    const [start, end] = dates;
    setStartDate(start);
    formik.setFieldValue("startDate", start);
    setEndDate(end);
    formik.setFieldValue("endDate", end);
  };

  return (
    <Formik
      initialValues={{
        actTypesSelected: selected,
        startDate: "",
        endDate: "",
      }}
      onSubmit={noop}
    >
      {(formik) => {
        return (
          <Form>
            <div className="flex flex-col items-start self-stretch px-6 my-auto max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col self-stretch pb-4 bg-white rounded-lg max-md:max-w-full">
                <div className="flex flex-col bg-white max-md:max-w-full">
                  <div className="flex gap-5 justify-between w-full text-sm text-black max-md:flex-wrap  max-md:max-w-full">
                    <div className="flex gap-5 justify-between mb-[6px]">
                      <div
                        className={`cursor-pointer body-lg ${fontClass(0)}`}
                        onClick={() => setActiveTab(0)}
                      >
                        {labels.filters}
                      </div>
                      <div
                        className={`cursor-pointer flex-auto body-lg ${fontClass(1)}`}
                        onClick={() => setActiveTab(1)}
                      >
                        {labels.advancedSearchTabLabel}
                      </div>
                    </div>
                    <Button onClick={() => setShowFilters(false)}>
                      <Icon
                        type="close"
                        color="#000"
                        className="w-[14px] h-[13px]"
                      />
                    </Button>
                  </div>
                  {activeTab === 0 && (
                    <div className="flex gap-10 mt-1.5 max-[1790px]:flex-wrap max-xl:max-w-full">
                      {activeTabUrl !== "/acts/closed" && (
                        <div className="flex flex-col">
                          <label htmlFor="status" className="body-sm mb-[10px]">
                            {labels.status}
                          </label>
                          <div className="w-[260px] md:w-[317px]">
                            <Multiselect
                              options={dropdownOptions}
                              selected={selected}
                              setSelected={setSelected}
                              allStatusOptions={labels.allStatuses}
                            />
                          </div>
                        </div>
                      )}

                      <div className="flex flex-col">
                        <label
                          htmlFor="startDate"
                          className="body-sm mb-[10px]"
                        >
                          {labels.creationDate}
                        </label>

                        <div className="relative">
                          <Icon
                            type="calendar"
                            color="#000"
                            className="w-[18px] h-[20px] mr-[19px] absolute top-[50%] left-[16px] transform -translate-y-1/2 z-10"
                          />
                          {/* @ts-ignore DatePicker requires null or undefined which throws error for formik */}
                          <DatePicker
                            renderCustomHeader={(props) => (
                              <CustomDatePickerHeader {...props} />
                            )}
                            className="font-medium w-[180px] md:w-[250px] text-black pl-[40px] py-2 pr-[10px] bg-white rounded border border-solid border-ea-gray-100"
                            selected={startDate}
                            onChange={(dates) =>
                              onChange(
                                [
                                  dates[0] ? dates[0] : "",
                                  dates[1] ? dates[1] : "",
                                ],
                                formik,
                              )
                            }
                            startDate={startDate}
                            endDate={endDate}
                            onBlur={(event) => {
                              setDates(
                                event.target.value,
                                formik,
                                setStartDate,
                                setEndDate,
                              );
                            }}
                            selectsRange
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        <Field
                          type="hidden"
                          name="startDate"
                          validate={() =>
                            isStartDateBeforeEndDate(
                              formik.values.startDate,
                              formik.values.endDate,
                            )
                          }
                        />
                        <Field
                          type="hidden"
                          name="endDate"
                          validate={() =>
                            isStartDateBeforeEndDate(
                              formik.values.startDate,
                              formik.values.endDate,
                            )
                          }
                        />
                        <ErrorMessage name="startDate">
                          {(msg) => (
                            <span className="text-[10px] max-w-[293px] text-ea-red">
                              {msg}
                            </span>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="flex flex-col">
                        <label htmlFor="title" className="body-sm mb-[10px]">
                          {labels.title}
                        </label>

                        <div className="relative">
                          <Icon
                            type="search"
                            color="#000"
                            className="w-[17px] h-[17px] absolute top-[50%] left-[16px] transform -translate-y-1/2"
                          />

                          <input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            type="text"
                            name="title"
                            className="font-medium text-black pl-[40px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <label htmlFor="title" className="body-sm mb-[10px]">
                          {labels.number}
                        </label>

                        <div className="relative">
                          <Icon
                            type="search"
                            color="#000"
                            className="w-[17px] h-[17px] absolute top-[50%] left-[16px] transform -translate-y-1/2"
                          />

                          <input
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            type="text"
                            name="number"
                            className="font-medium text-black pl-[40px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5"
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-center mt-[30px] ml-auto text-white whitespace-nowrap">
                        <Button
                          onClick={() => resetFilters(formik)}
                          type="button"
                        >
                          <Icon
                            type="refresh"
                            color="#7F7F7F"
                            className="w-[16px] h-[16px] mr-[16px]"
                          />
                        </Button>
                        <Button
                          type="button"
                          onClick={handleSearch}
                          className="bg-ea-darkblue text-white body-md px-[16px] py-[6px] rounded-lg btn-secondary-emphasize"
                          disabled={params.fetchingActs}
                        >
                          {labels.apply}
                        </Button>
                      </div>
                    </div>
                  )}
                  {activeTab === 1 && (
                    <div className="flex gap-5 justify-between mt-1.5 max-xl:flex-wrap max-md:pr-5 max-xl:max-w-full">
                      <div className="flex flex-col flex-1">
                        <label
                          htmlFor="partyName"
                          className="body-sm mb-[10px]"
                        >
                          {labels.nameOfParty}
                        </label>
                        <div className="relative">
                          <Icon
                            type="search"
                            color="#000"
                            className="w-[17px] h-[17px] absolute top-[50%] left-[16px] transform -translate-y-1/2"
                          />
                          <input
                            value={partyName}
                            onChange={(e) => setPartyName(e.target.value)}
                            type="text"
                            name="partyName"
                            className="font-medium text-black pl-[40px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col flex-1">
                        <label
                          htmlFor="lawyerName"
                          className="body-sm mb-[10px]"
                        >
                          {labels.lawyerName}
                        </label>
                        <div className="relative">
                          <Icon
                            type="search"
                            color="#000"
                            className="w-[17px] h-[17px] absolute top-[50%] left-[16px] transform -translate-y-1/2"
                          />
                          <input
                            value={lawyerName}
                            onChange={(e) => setLawyerName(e.target.value)}
                            type="text"
                            name="lawyerName"
                            className="font-medium text-black pl-[40px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col flex-1">
                        <label htmlFor="keyword" className="body-sm mb-[10px]">
                          {labels.keywords}
                        </label>
                        <div className="relative">
                          <Icon
                            type="search"
                            color="#000"
                            className="w-[17px] h-[17px] absolute top-[50%] left-[16px] transform -translate-y-1/2"
                          />
                          <input
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            type="text"
                            name="keyword"
                            className="font-medium text-black pl-[40px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5"
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-center mt-[30px] text-white whitespace-nowrap">
                        <Button onClick={resetInputs} type="button">
                          <Icon
                            type="refresh"
                            color="#7F7F7F"
                            className="w-[16px] h-[16px] mr-[16px]"
                          />
                        </Button>
                        <Button
                          type="button"
                          onClick={handleSearch}
                          className="bg-ea-darkblue text-white body-md px-[16px] py-[6px] rounded-lg btn-secondary-emphasize"
                          disabled={params.fetchingActs}
                        >
                          {labels.search}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Filters;
