const frenchPhoneNumberRegex = /^[1-9]([ .-]?[0-9]{2}){4}$|^[1-9][0-9]{8}$/;
const nameRegex = /^[a-zA-ZÀ-ÿ\s<>-]*$/;
const addressRegex = /^[a-zA-ZÀ-ÿ0-9\s-/.']*$/;
const postCodeRegex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;
const cityName = /^[a-zA-ZÀ-ÿ\s'-]+(?:\s[a-zA-ZÀ-ÿ\s'-]+)?$/;
const dateBirthRegex =
  /^(0?[1-9]|[12][0-9]|3[01])[-/](0?[1-9]|1[0-2])[-/](19|20)\d{2}$/;

export {
  frenchPhoneNumberRegex,
  nameRegex,
  dateBirthRegex,
  postCodeRegex,
  addressRegex,
  cityName,
};
