import { useDispatch, useSelector } from "react-redux";

import Icon from "@ui/Icon";

import useOrientation from "@hooks/useOrientation";

import { selectPostState, setLoadingState } from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

import { ModalProps } from "@types";

const Modal = ({
  title,
  description,
  width,
  height,
  children,
  paddingClass = "py-[24px] px-[42px]",
  closable = true,
  blurredOverlay = false,
}: ModalProps) => {
  const dispatch = useDispatch();
  const modalWidth = width ? `${width}px` : "auto";

  const isPortrait = useOrientation();
  const isDisabled = useSelector(selectPostState);

  const blurredOverlayClass = blurredOverlay
    ? "bg-white/20 backdrop-blur"
    : "bg-black/25";
  const overflowClass = !isPortrait ? "overflow-y-scroll" : "";
  const disableCloseButton = isDisabled
    ? "cursor-not-allowed pointer-events-none"
    : "";

  return (
    <div
      {...(closable && { "data-closable": 1 })}
      className={`fixed left-0 top-0 w-full h-full ${blurredOverlayClass} z-[350]`}
    >
      <div
        className={`absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 ${paddingClass} bg-white box-shadow rounded text-left w-full ${overflowClass}`}
        style={{
          maxWidth: modalWidth,
          height: height ? `${height}px` : "auto",
        }}
      >
        <div className="relative flex-row">
          {closable && (
            <span
              className={`absolute top-[9px] right-0 cursor-pointer bg-white ${disableCloseButton}`}
              onClick={() => {
                dispatch(setLoadingState(false));
                dispatch(hideModal());
              }}
              data-testid="close-button"
            >
              <Icon type="close" className="size-[13px]" />
            </span>
          )}
          {title && <h2 className="headline-lg pr-4 text-left">{title}</h2>}
          {description && (
            <p className="body-sm text-justify mt-[10px]">{description}</p>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
