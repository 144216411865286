import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectSettings } from "@reducers/metadataSlice";

const Matomo = () => {
  const { matomoSiteId, matomoStatsServer } = useSelector(selectSettings);

  useEffect(() => {
    if (!matomoSiteId || !matomoStatsServer) {
      return;
    }
    const safeMatomoServer = encodeURIComponent(matomoStatsServer);
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.innerHTML = `
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        const u="${safeMatomoServer}";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', ${matomoSiteId}]);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
   `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Matomo;
