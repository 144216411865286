import { useKeycloak } from "@react-keycloak/web";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Loader from "@ui/Loader";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import {
  selectFormikData,
  selectLoadingState,
  selectPageData,
  selectPrintData,
  setActData,
  setFormikData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { selectTokens } from "@reducers/eafSlice";
import {
  selectErrors,
  selectPrepaidAccountInfo,
  selectUser,
} from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";
import { showOverlay } from "@reducers/overlaySlice";

import { HeaderProps } from "@types";

import {
  downloadFile,
  downloadPDF,
  extractFileName,
  filterTokens,
  formatDateBirth,
  formatLabel,
  getPathMap,
  getStepFromPathMap,
  mergeAndPrint,
  printFile,
} from "@utils";

const Header = (props: HeaderProps) => {
  const prepaidAccountInfo = useSelector(selectPrepaidAccountInfo);
  const balanceAmount = prepaidAccountInfo?.balance
    .toString()
    .replace(".", ",");
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
  // Might be used when backend is implemented
  // eslint-disable-next-line
  const [avatar, setAvatar] = useState("");
  // Might be used when backend is implemented
  // eslint-disable-next-line
  const { onboarding, sidebar, helpButton, logoLink = true } = props;
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const navigate = useNavigate();
  const formikData = useSelector(selectFormikData);
  const errors = useSelector(selectErrors);
  const user = useSelector(selectUser);
  const data = useSelector(selectPageData);
  const tokens = useSelector(selectTokens);
  const isDisabledButton = useSelector(selectLoadingState);

  const { actType, "*": rest } = useParams();
  const id = rest ? rest.split("/")[0] : undefined;
  const pathMap = getPathMap(actType, id);
  const queryParams = new URLSearchParams(location.search);
  const returnPath = queryParams.get("returnPath");
  const documentId = queryParams.get("documentId");
  const actId = queryParams.get("actId");
  const documentName = queryParams.get("documentName") || "document.pdf";
  const printUrls = useSelector(selectPrintData);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.logOut) {
      setPageData(null);
      keycloak.logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, keycloak]);

  const isWithinDays = (expirationDate: Date, days: number = 7) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    expirationDate.setDate(expirationDate.getDate() - days);

    expirationDate.setHours(0, 0, 0, 0);

    return today >= expirationDate;
  };

  // To add the diffrent state of the signature alert depending on case
  const toggleSignatureAlert = () => {
    let alertClass = "text-yellow-800 bg-ea-yellow";
    let alertIconColor = "#7E5B00";
    let alertIconType = "dongle-connection";
    let alertText = labels.keyNotConnected;

    const token = filterTokens(tokens, user.numCNBF);

    if (token) {
      alertClass = "text-black bg-green-200";
      alertIconColor = "#000";
      alertIconType = "check";

      const tokenValidity = token.certificate?.json?.validity?.notAfter;
      const tokenValidityDate = tokenValidity
        ? formatDateBirth(new Date(tokenValidity))
        : "";

      // If the token expires in 14 days or less show the orange alert
      if (isWithinDays(new Date(tokenValidity), 14)) {
        alertClass = "text-yellow-800 bg-ea-yellow";
        alertIconColor = "#000";
        alertIconType = "exclamation-triangle";
      }

      // If the token expires in 3 days or less show the red alert
      if (isWithinDays(new Date(tokenValidity), 3)) {
        alertClass = "text-white bg-[#E40000]";
        alertIconColor = "#fff";
        alertIconType = "exclamation-triangle";
      }

      alertText = formatLabel(labels.keyConnected, tokenValidityDate);
    }

    return (
      <div
        className={`hidden md:flex text-center ${alertClass} z-10 w-auto py-[5px] px-[12px] hidden lg:flex`}
      >
        <Icon
          type={alertIconType}
          className="z-10 w-[24px] h-[24px] mr-[8px]"
          color={alertIconColor}
        />
        <div className="text-center text-xs font-semibold body-md z-10 mt-1">
          <p>{alertText}</p>
        </div>
      </div>
    );
  };

  const toggleSubmenu = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setIsSubmenuVisible(!isSubmenuVisible);
  };

  useEffect(() => {
    const hideSubmenu = () => {
      if (isSubmenuVisible) {
        setIsSubmenuVisible(false);
      }
    };

    window.addEventListener("click", hideSubmenu);

    return () => {
      window.removeEventListener("click", hideSubmenu);
    };
  }, [isSubmenuVisible]);

  const shouldShowConfirmationModal = () => {
    const splittedPath = location.pathname.split("/");

    const locationPath = splittedPath[splittedPath.length - 1];

    const currentStep = getStepFromPathMap(locationPath, pathMap);

    // If we are not in the first or last step, we can navigate away
    if (
      (!currentStep || ![1, 4].includes(currentStep)) &&
      splittedPath[1] !== "profile"
    ) {
      return false;
    }

    // If there is no Formik data stored, we can navigate away
    if (!formikData) {
      return false;
    }

    // If there are no changes in the form, we can clear the stored data and navigate away
    if (!formikData.hasChanges) {
      dispatch(setFormikData(null));

      return false;
    }

    return true;
  };

  const handleQuitAction = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
    redirectTo: string,
    hasNativeRedirect: boolean = false,
  ) => {
    const targetedElement = (event.target as HTMLElement).dataset.testid;

    if (!logoLink && !targetedElement) {
      event.preventDefault();
      return;
    }

    if (props.quitButtonWithoutModal) {
      navigate(redirectTo);
      return;
    }
    // Check if conditions for showing the confirmation modal are met
    // and show it if needed
    const showConfirmationModal = shouldShowConfirmationModal();

    if (showConfirmationModal) {
      event.preventDefault();

      dispatch(setModalData({ redirectTo }));
      dispatch(showModal("cancel"));
      return;
    }

    // If there is no confirmation modal and the element is a button, navigate away
    if (!hasNativeRedirect) {
      navigate(redirectTo);
    }

    dispatch(setActData(null));
  };

  const handleLogOut = (event: any) => {
    const showConfirmationModal = shouldShowConfirmationModal();
    if (showConfirmationModal) {
      event.preventDefault();

      dispatch(setModalData({ redirectTo: location.pathname, logOut: true }));
      dispatch(showModal("logOut"));

      return;
    }
    localStorage.clear();
    keycloak.logout();
  };

  const logoLinkClass = !sidebar ? "visible" : "invisible";
  const logoCursorClass = logoLink ? "" : "cursor-default";
  const menuIsActive = isSubmenuVisible
    ? "bg-violet-100 py-[19px] px-4 z-0"
    : "";

  return (
    <header className="relative z-[12] w-full h-[77px] flex justify-between border-b-[1px] px-[24px] lg:px-0 bg-white">
      <NavLink
        className={`hidden lg:block w-[102px] h-[60px] mt-[8px] ml-[74px] ${logoLinkClass} ${logoCursorClass}`}
        to={"/"}
        onClick={(event) => handleQuitAction(event, "/", true)}
      >
        <img srcSet="/assets/logo.svg" alt="" />
      </NavLink>
      <div className="relative h-full w-full flex flex-row z-0 items-center justify-end mr-0 lg:mr-[74px]">
        {sidebar && (
          <button
            className="text-black absolute top-[50%] left-0 -translate-y-2/4 xl:hidden"
            onClick={() => {
              dispatch(showOverlay("sidebar"));
            }}
          >
            <Icon type="hamburger-menu" className="size-[34px]" />
          </button>
        )}
        {!onboarding && toggleSignatureAlert()}
        {helpButton && (
          <a
            href="https://assistance.cnb.avocat.fr/hc/fr/sections/360001418320-e-acte-E-acte-d-Avocat-"
            className="text-center text-black text-sm  relative ml-[10px] hover:bg-violet-100 py-[28px] px-[14px] body-lg hidden lg:flex"
            target="_blank"
            rel="noreferrer"
            data-testid="help-link"
          >
            <Icon
              type="help"
              className="w-[20px] h-[20px] aspect-auto mr-2"
              color="black"
            />
            {labels.help}
          </a>
        )}

        {!onboarding && (
          <>
            {props.downloadButton ? (
              <Button
                className="btn-secondary px-[15px] py-[4px] rounded-[8px] mr-[10px] hidden md:block"
                onClick={() => {
                  if (!documentId) {
                    const urls = data.pdfForDownload;
                    downloadPDF(urls);
                    return;
                  }
                  axios
                    .get(
                      `api/v1/signbooks/${actId}/documents/${documentId}/data`,
                      {
                        responseType: "arraybuffer",
                      },
                    )
                    .then((response) => {
                      const contentDisposition =
                        response.headers["content-disposition"];
                      const filename = extractFileName(contentDisposition);
                      const contentType = response.headers["content-type"];

                      // Create a Blob from the response data
                      const fileBlob = new Blob([response.data], {
                        type: contentType,
                      });

                      if (contentType === "application/pdf") {
                        const fileUrl = URL.createObjectURL(fileBlob);
                        downloadPDF([fileUrl], filename);
                      } else {
                        downloadFile(fileBlob, filename);
                      }
                    })
                    .catch((error) => {
                      toast.error(
                        axiosErrorMessages[error.message],
                        toastOptionsError,
                      );
                    });
                }}
                data-testid="download-button"
              >
                {labels.download}
              </Button>
            ) : null}

            {props.printButton ? (
              <Button
                className="btn-secondary px-[15px] py-[4px] rounded-[8px] mr-[10px]"
                onClick={() => {
                  if (!documentId) {
                    mergeAndPrint(printUrls);
                    return;
                  }
                  axios
                    .get(
                      `api/v1/signbooks/${actId}/documents/${documentId}/data`,
                      {
                        responseType: "arraybuffer",
                      },
                    )
                    .then((response) => {
                      const contentType = response.headers["content-type"];

                      // Create a Blob from the response data
                      const fileBlob = new Blob([response.data], {
                        type: contentType,
                      });

                      if (contentType === "application/pdf") {
                        const fileUrl = URL.createObjectURL(fileBlob);
                        mergeAndPrint([fileUrl]);
                      } else {
                        printFile(fileBlob, documentName);
                      }
                    })
                    .catch((error) => {
                      toast.error(
                        axiosErrorMessages[error.message],
                        toastOptionsError,
                      );
                    });
                }}
                data-testid="print-button"
              >
                {labels.print}
              </Button>
            ) : null}
            {props.quitButton ? (
              <Button
                className="btn-secondary px-[15px] py-[4px] rounded-[8px]"
                disabled={isDisabledButton}
                onClick={(event) => {
                  const actTypePath = actType ? `/${actType}` : "";
                  const quitLocation = returnPath || `/acts${actTypePath}`;
                  handleQuitAction(event, quitLocation);
                }}
                data-testid="quit-button"
              >
                {labels.quit}
              </Button>
            ) : (
              <div
                className={`cursor-pointer text-center text-black text-sm mr-[3px] flex items-center relative hover:bg-violet-100 py-[19px] px-4 z-0 ${menuIsActive}`}
                onClick={(event) => toggleSubmenu(event)}
                data-testid="profile-menu"
              >
                {avatar ? (
                  <img
                    className="size-[38px] rounded-[80.50px] md:mr-[12px]"
                    srcSet={avatar}
                    alt="avatar"
                  />
                ) : (
                  <Icon
                    type="profile-male"
                    className="size-[38px] aspect-auto rounded-[80.50px] md:mr-[12px]"
                  />
                )}
                <div className="body-lg">
                  <span
                    className="select-none"
                    onClick={(event) => toggleSubmenu(event)}
                  >
                    {!errors.userInfo && "" !== props.userName ? (
                      <span className="hidden md:inline">{props.userName}</span>
                    ) : (
                      <span className="hidden md:inline-block w-[100px]">
                        <Loader
                          error={errors.userInfo ? labels.userInfo : ""}
                        />
                      </span>
                    )}
                  </span>
                  {isSubmenuVisible ? (
                    <ul
                      className="w-[279px] h-[180px] py-[5px] absolute top-[75%] -right-[20px] lg:right-[-30px] mt-2 bg-white shadow-md shadow-black/25 text-left rounded-[4px] leading-[25.35px] z-50 cursor-default"
                      data-testid="profile-menu-list"
                    >
                      <li>
                        <NavLink
                          to="/profile"
                          className="block px-[30px] hover:bg-violet-100 py-1"
                          data-testid="profile-link"
                        >
                          {labels.myProfile}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/profile#my-prepaid-account"
                          className="flex justify-between px-[30px] hover:bg-violet-100 py-1"
                          data-testid="prepaid-account-link"
                        >
                          <p>{labels.myPrepaidAccount}</p>
                          <div className="flex justify-center">
                            <p className="font-bold mr-[2px]">
                              {balanceAmount}
                            </p>
                            <p>{labels.euroSign}</p>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <Button
                          className="block w-full text-left px-[30px] hover:bg-violet-100 py-1"
                          onClick={() => {
                            dispatch(showModal("invoiceHistory"));
                          }}
                          data-testid="invoice-history"
                        >
                          {labels.invoicesHistory}
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="block w-full text-left px-[30px] hover:bg-violet-100 py-1"
                          onClick={() => {
                            dispatch(showModal("diagnostics"));
                          }}
                          data-testid="diagnostic-link"
                        >
                          {labels.diagnostic}
                        </Button>
                      </li>
                      <li>
                        <NavLink
                          to="#"
                          className="block px-[30px] hover:bg-violet-100 py-1"
                          onClick={(event) => handleLogOut(event)}
                        >
                          {labels.signOut}
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
