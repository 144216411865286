import axios from "axios";
import { Field, Form, Formik } from "formik";
import "pdfjs-dist/build/pdf.worker.entry";
import { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import Layout from "@components/layout/Layout";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import ProgressBar from "@ui/ProgressBar";
import Spinner from "@ui/Spinner";

import {
  actMapAmount,
  axiosErrorMessages,
  labels,
  toastOptionsError,
} from "@constants";

import useIsMobile from "@hooks/useIsMobile";
import useOrientation from "@hooks/useOrientation";

import {
  selectModalData,
  selectPageData,
  selectPostState,
  setPageData,
  setPrintData,
} from "@reducers/dataTransferSlice";
import { setModalData } from "@reducers/dataTransferSlice";
import { selectSettings, selectUser } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";
import {
  selectSignedDocument,
  setSignedDocument,
} from "@reducers/validationSlice";

import { SignBookStatus } from "@types";

import { get } from "@utils";
import { fetchSignBooks } from "@utils/api/api";
import { documentToPrint } from "@utils/api/printApi";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

const DocumentPreview = ({
  lawyerSigning = false,
}: {
  lawyerSigning?: boolean;
}) => {
  const [pdf, setPdf] = useState<number>(0);
  const [mimeType, setMimeType] = useState<string>("application/pdf");
  const [fileUrl, setFileUrl] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [numPagesMap, setNumPagesMap] = useState<Record<string, number>>({});
  const [zoom, setZoom] = useState<number>(1);
  const [hasError, setHasError] = useState(false);
  const [hasReadAll, setHasReadAll] = useState(false);
  const documentRef = useRef(null);
  const pdfContainerRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const documentId: string | null = queryParams.get("documentId");
  const actId =
    queryParams.get("actId") || queryParams.get("signBookNumber") || "";
  const processNumber = queryParams.get("processNumber") || "";
  const actorCode = queryParams.get("actorCode") || "";
  const mustReadAll = "true" === queryParams.get("mustReadAll");
  const isSecure = "false" !== queryParams.get("isSecure");

  const signedDocumentStatus = useSelector(selectSignedDocument);
  const action = queryParams.get("action") || "";
  const hasActionButton =
    !signedDocumentStatus && ("sign" === action || lawyerSigning);

  const inPersonSignature = queryParams.get("inPersonSignature") === "true";
  const actTitle = queryParams.get("actTitle") || "";
  const lawyerId = queryParams.get("lawyer") === "true" || lawyerSigning;
  const logoLink = queryParams.get("logoLink") === "true";
  const data = useSelector(selectModalData);
  const [readAllDocumentsChecked, setReadAllDocumentsChecked] = useState(false);
  const [acceptedCondition, setAcceptedCondition] = useState(false);
  const [pdfUrls, setPdfUrls] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pdfUids, setPdfUids] = useState<string[]>([]);
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const [signBookStatus, setSignBookStatus] = useState<SignBookStatus | null>(
    null,
  );
  const [loadedPdfUids, setLoadedPdfUids] = useState<string[]>([]);
  const [loadedPdfNames, setLoadedPdfNames] = useState<string[]>([]);
  const isPortrait = useOrientation();
  const isMobile = useIsMobile();
  const pageData = useSelector(selectPageData);
  const settings = useSelector(selectSettings);
  const paymentState = useSelector(selectPostState);
  const hasMultiplePdfs = (!documentId && actId) as boolean;
  const [order, setOrder] = useState<number>(0);
  const [signatoryName, setSignatoryName] = useState<string>("");
  const [lawyerCode, setLawyerCode] = useState<string>("");
  const [actCreator, setActCreator] = useState<string>("");
  const [isActCreator, setIsActCreator] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>("");
  const user = useSelector(selectUser);
  const [accessToken, setAccessToken] = useState<string>("");
  const [actType, setActType] = useState<string>("");
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    dispatch(setSignedDocument(false));
    const fetchData = async () => {
      const response = await fetchSignBooks(actId);

      const { metaDataValues, publicId, name, type, step, actCreatorCNBF } =
        response;

      const paymentStatusValue = metaDataValues?.find(
        (item: { code: string }) => item.code === "PAYMENT_STATUS",
      )?.value;

      const signBookStateValues = {
        publicId: publicId,
        title: name,
        type: type,
        status: paymentStatusValue,
      };

      setActType(type);
      setIsClosed("closed" === step);

      if ("dcm" === type && step === "signing" && isSecure) {
        const order = await getOrder();

        if (Number(order) === 3) {
          await axios
            .post(
              `/shared-api/v1/signbooks/${processNumber}/actors/${actorCode}/authenticate`,
              {},
            )
            .then((response) => {
              const { accessToken } = response.data;
              setAccessToken(accessToken);
            })
            .catch((error) => {
              toast.error(axiosErrorMessages[error.message], toastOptionsError);
            });
        }
      }

      if (step) {
        setCurrentStep(step);
      }

      if (actCreatorCNBF) {
        setActCreator(actCreatorCNBF);
      }

      setSignBookStatus(signBookStateValues);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user || "" === actCreator) {
      return;
    }

    const isCreator = user.numCNBF === actCreator;

    setIsActCreator(isCreator);
  }, [user, actCreator]);

  const fetchPdfData = async (actId: string, documentId: string) => {
    if (loadedPdfUids.includes(documentId)) {
      return;
    }

    if (documents.length === 0 && order !== 0) {
      return;
    }

    setLoading(true);

    let requestUrl = `api/v1/signbooks/${actId}/documents/${documentId}/data`;
    let requestOptions: any = {
      responseType: "arraybuffer",
      headers: {} as any,
    };

    // Will be updated later when `main-documents` is fixed for logged in user
    if (accessToken !== "") {
      requestUrl = `/shared-api/v1/process/${processNumber}/actors/${actorCode}/signbooks/${actId}/main-documents`;
      requestOptions.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (isClosed) {
      requestUrl = `api/v1/signbooks/${actId}/main-documents/${documentId}`;
    }

    const documentToSend = documents.find(
      (document: any) => document.documentUid === documentId,
    );

    try {
      let response;
      if (accessToken !== "") {
        response = await axios.post(requestUrl, documentToSend, requestOptions);
      } else {
        response = await axios.get(requestUrl, requestOptions);
      }

      const contentType = response.headers["content-type"];
      setMimeType(contentType);
      const fileBlob = new Blob([response.data], { type: contentType });
      const fileUrl = URL.createObjectURL(fileBlob);

      if (mimeType !== "application/pdf") {
        setFileUrl(fileUrl);
        setLoading(false);
        return;
      } else {
        setPdfUrls([...pdfUrls, fileUrl]);
      }
      dispatch(
        setPageData({ ...pageData, pdfForDownload: [...pdfUrls, fileUrl] }),
      );

      if (contentType === "application/pdf") {
        setLoadedPdfUids([...loadedPdfUids, documentId]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    }
  };

  useEffect(() => {
    if (documents.length === 0) {
      return;
    }
    const fetchFileUrls = async () => {
      const fileUrls = await documentToPrint(
        processNumber,
        actorCode,
        actId,
        accessToken,
        documents,
        isClosed,
      );

      dispatch(setPrintData(fileUrls));
    };
    fetchFileUrls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  const getOrder = async () => {
    return axios
      .get(`/api/v1/signbooks/${actId}/signings/order`)
      .then((response) => {
        const orderData = response.data;
        setOrder(orderData.nextActor.order);
        setLawyerCode(orderData.nextActor.code);
        setSignatoryName(
          `${orderData.nextActor.lastName} ${orderData.nextActor.firstName}`,
        );

        return orderData.nextActor.order;
      })
      .catch((error) => {
        if (404 === error?.response?.status) {
          return;
        }

        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  useEffect(() => {
    // fetchPdfData only if pdfUids and documents are populated correctly
    if (pdfUids.length > 0 && documents.length > 0) {
      fetchPdfData(actId, pdfUids[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfUids, documents]);

  useEffect(() => {
    if ("" === actType) {
      return;
    }

    if (
      isSecure &&
      "dcm" === actType &&
      Number(order) === 3 &&
      accessToken === ""
    ) {
      return;
    }

    const fetchMultiplePdfs = async (actId: string) => {
      setLoading(true);
      try {
        let uids;

        let requestUrl = `api/v1/signbooks/${actId}/documents`;
        let responsePath = "data";

        if (isClosed) {
          requestUrl = `api/v1/signbooks/${actId}/main-documents`;
          responsePath = "data._embedded.mainDocumentList";
        }

        let requestOptions: any = {
          headers: {} as any,
        };

        let shouldParseResponse = false;

        // Will be updated later when `main-documents` is fixed for logged in user
        if (Number(order) === 3 && accessToken !== "") {
          requestOptions.responseType = "application/json";
          requestOptions.headers.Authorization = `Bearer ${accessToken}`;

          requestUrl = `/shared-api/v1/process/${processNumber}/actors/${actorCode}/main-documents`;

          shouldParseResponse = true;
        }

        let response = await axios.get(requestUrl, requestOptions);
        let responseData = get(response, responsePath, []);

        if (shouldParseResponse) {
          responseData = JSON.parse(response.data);
        }
        setDocuments(responseData);

        // Sort the PDFs, placing the one with type "CGU" first, then map to get the uids
        uids = responseData
          .sort((a: any, b: any) =>
            a.type === "CGU" ? -1 : b.type === "CGU" ? 1 : 0,
          )
          .map((pdf: any) => pdf.documentUid);

        setPdfUids(uids);
        setLoadedPdfNames(responseData.map((pdf: any) => pdf.documentName));
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      }
    };

    if (hasMultiplePdfs) {
      fetchMultiplePdfs(actId);
    } else if (documentId) {
      fetchPdfData(actId, documentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, mimeType, accessToken, actType, order]);

  useEffect(() => {
    if (!inPersonSignature && !lawyerId) {
      dispatch(showModal("restrictedAccess"));
    }
  }, [inPersonSignature, dispatch, lawyerId]);

  useEffect(() => {
    const pdfPreview = document.querySelector(".pdf-container");

    if (pdfPreview) {
      (pdfPreview as HTMLElement).style.overflow =
        zoom !== 1 ? "auto" : "hidden";
    }
  }, [zoom]);

  useEffect(() => {
    let lastPageSeen = false;
    if (fileUrl.length > 0) {
      return;
    }
    const checkLastPageVisibility = () => {
      const lastPdf = pdfUrls.length - 1;

      const lastPage = numPagesMap[pdfUrls[pdfUrls.length - 1]];
      const lastPageElement = document.getElementById(
        `pdf_${lastPdf}_page_${lastPage}`,
      );

      if (lastPageElement) {
        const containerElement = lastPageElement.closest(
          ".scrollable-container",
        );

        if (containerElement) {
          const containerRect = containerElement.getBoundingClientRect();
          const lastPageRect = lastPageElement.getBoundingClientRect();
          const isVisible =
            lastPageRect.top >= containerRect.top &&
            lastPageRect.bottom <= containerRect.bottom;

          if (isVisible) {
            // Mark the last page as seen and set hasReadAll to true
            lastPageSeen = true;
            if (pdfUids.length === pdfUrls.length) {
              setHasReadAll(true);
            }
          } else {
            // If the last page is not visible and has been seen before, reset lastPageSeen
            if (lastPageSeen) {
              lastPageSeen = false;
            }
          }
        }
      }
    };

    const containerElement = document.querySelector(".scrollable-container");
    if (containerElement) {
      containerElement.addEventListener("scroll", checkLastPageVisibility);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener("scroll", checkLastPageVisibility);
      }
    };
  }, [numPagesMap, pdfUrls, fileUrl, pdfUids]);

  // When the component is loading, disable scroll
  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [loading]);

  const onDocumentLoadSuccess = (
    { numPages }: { numPages: number },
    pdfUrl: string,
  ): void => {
    setNumPagesMap({
      ...numPagesMap,
      [pdfUrl]: numPages,
    });
  };

  const scrollToThumbnail = (pdf: number, page: number) => {
    const thumbnail: HTMLElement | null = document.getElementById(
      `pdf_${pdf}_page_${page}`,
    );

    if (thumbnail) {
      const scrollableContainer: HTMLElement | Window =
        (thumbnail.closest(".scrollable-container") as HTMLElement) || window;
      const thumbnailRect: DOMRect = thumbnail.getBoundingClientRect();
      const containerRect: DOMRect =
        scrollableContainer instanceof Window
          ? {
              top: 0,
              left: 0,
              bottom: window.innerHeight,
              right: window.innerWidth,
              width: window.innerWidth,
              height: window.innerHeight,
              x: 0,
              y: 0,
              toJSON: () => {
                /* empty implementation */
              },
            }
          : scrollableContainer.getBoundingClientRect();

      const scrollOptions: ScrollToOptions = {
        top:
          thumbnailRect.top -
          containerRect.top +
          (scrollableContainer instanceof Window
            ? window.scrollY
            : scrollableContainer.scrollTop) -
          containerRect.height / 2 +
          thumbnailRect.height / 2,
        left:
          thumbnailRect.left -
          containerRect.left +
          (scrollableContainer instanceof Window
            ? window.scrollX
            : scrollableContainer.scrollLeft) -
          containerRect.width / 2 +
          thumbnailRect.width / 2,
        behavior: "smooth",
      };

      if (scrollableContainer instanceof Window) {
        window.scrollTo(scrollOptions);
      } else {
        scrollableContainer.scrollTo(scrollOptions);
      }
    }
  };

  const prevPage = () => {
    let thumbnailPdf = pdf;
    let thumbnailPage = page;

    if (page > 1) {
      setPage(page - 1);
      thumbnailPage = page - 1;
    } else if (pdf > 0) {
      setPdf(pdf - 1);
      setPage(numPagesMap[pdfUrls[pdf - 1]]);

      thumbnailPdf = pdf - 1;
      thumbnailPage = numPagesMap[pdfUrls[pdf - 1]];
    } else if (pdf === 0 && page === 1) {
      setPdf(pdfUrls.length - 1);
      setPage(numPagesMap[pdfUrls[pdfUrls.length - 1]]);

      thumbnailPdf = pdfUrls.length - 1;
      thumbnailPage = numPagesMap[pdfUrls[pdfUrls.length - 1]];
    }

    scrollToThumbnail(thumbnailPdf, thumbnailPage);
  };

  useEffect(() => {
    if (documentId) {
      return;
    }
    const currentPdfPages = numPagesMap[pdfUrls[pdf]];
    const hasMorePdfs = pdfUids.length !== loadedPdfUids.length;

    if (currentPdfPages === page) {
      hasMorePdfs ? fetchPdfData(actId, pdfUids[pdf + 1]) : setHasReadAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf, page]);

  const nextPage = () => {
    if (loading) {
      return;
    }
    let thumbnailPdf = pdf;
    let thumbnailPage = page;

    // If you have not reached the last page of the document
    if (page < numPagesMap[pdfUrls[pdf]]) {
      setPage(page + 1);
      thumbnailPage = page + 1;
      //If the last page of the document has been reached
    } else if (pdf < pdfUrls.length - 1) {
      setPdf(pdf + 1);
      setPage(1);
      thumbnailPdf = pdf + 1;
      thumbnailPage = 1;
      // If you are on the last page of the last document and there are no more documents to fetch
    } else if (pdf === pdfUrls.length && page === numPagesMap[pdfUrls[pdf]]) {
      setPdf(0);
      setPage(1);

      thumbnailPdf = 0;
      thumbnailPage = 1;
    }

    scrollToThumbnail(thumbnailPdf, thumbnailPage);
  };

  const handleChangingPage = (
    pdfIndex: number,
    index: number,
    pdfUrl: string,
  ) => {
    setPdf(pdfIndex);
    setPage(index + 1);

    if (documentId) {
      return;
    }
  };

  const errorTextClass = hasError ? "text-ea-red" : "text-black";

  const initialValues = {
    validated: false,
    acceptedTerms: false,
    documentRead: false,
  };

  const areDocumentsAccepted = readAllDocumentsChecked && acceptedCondition;

  const isDisabled =
    action !== "validate" &&
    (mustReadAll
      ? !(hasReadAll && areDocumentsAccepted)
      : !areDocumentsAccepted);

  const documentContainerHeightClass = mustReadAll
    ? "min-h-[calc((100dvh-77px)-161px)]"
    : "min-h-[calc(100dvh-77px)]";

  const documentWidth = isMobile ? (isPortrait ? 300 : 250) : 600;

  const documentDistanceClass = isMobile
    ? isPortrait
      ? "top-[280px]"
      : "top-[128px]"
    : "top-[280px]";

  const handlePayment = () => {
    if (!signBookStatus) {
      return;
    }
    const mappedActType = signBookStatus.type;
    const amount = actMapAmount[mappedActType as keyof typeof actMapAmount];
    const actAmount = Number(settings[amount as keyof typeof settings]);
    const paymentFetchedData = {
      ...signBookStatus,
      amount: actAmount,
    };

    dispatch(setModalData(paymentFetchedData));
    dispatch(showModal("payment"));
  };

  const borderBottom = hasActionButton ? "border-b border-slate-400" : "";

  return (
    <Layout
      sidebar={false}
      backgroundColor="bg-[#F9F9F9]"
      downloadButton={true}
      quitButton={true}
      printButton={true}
      quitButtonWithoutModal={true}
      logoLink={logoLink}
      onboarding={hasMultiplePdfs && !lawyerSigning}
    >
      <div className="relative overflow-hidden min-h-[calc(100dvh-77px)] h-full">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            if (lawyerSigning) {
              dispatch(
                setModalData({
                  documents: loadedPdfNames,
                  order: Number(order),
                  lawyerCode: "" !== lawyerCode ? lawyerCode : user?.numCNBF,
                  isSecure: isSecure,
                }),
              );
              dispatch(showModal("signature"));

              return;
            }

            let updatedData = {
              ...data,
              ...values,
              signed: true,
            };

            if (!values.acceptedTerms || !values.documentRead) {
              setHasError(true);
              return;
            } else {
              updatedData.validated = true;
              setHasError(false);
              dispatch(setPageData({ ...pageData, updatedData }));

              dispatch(showModal("certificate"));
            }
          }}
        >
          {(formik) => (
            <Form>
              {/* Arrows previous/next */}
              <div className="z-10 flex flex-col items-center xl:items-start justify-center w-full xl:w-auto mx-auto fixed xl:left-[20px] top-[85px]">
                <h2 className="headline-lg mb-1 lg:mb-[17px] bg-[#F9F9F9]">
                  {actTitle}
                </h2>
                <div className="flex items-center text-center">
                  <div className="flex items-center">
                    <Button
                      onClick={() => {
                        prevPage();
                      }}
                      type="button"
                      className="px-[20px] py-[10px] btn-secondary border-r-0 rounded-l-[8px]"
                    >
                      <Icon
                        type="arrow-left-pagination"
                        className="w-[7px] h-[14px]"
                      />
                    </Button>
                    <p className="body-md border border-ea-darkblue px-[10px] py-[8px] bg-white">
                      {labels.page} {page} - {labels.doc} {pdf + 1}
                    </p>
                    <Button
                      onClick={() => {
                        nextPage();
                      }}
                      type="button"
                      className="px-[20px] py-[10px] btn-secondary border-l-0 rounded-r-[8px]"
                    >
                      <Icon
                        type="arrow-right-pagination"
                        className="w-[7px] h-[14px]"
                      />
                    </Button>
                  </div>
                </div>
              </div>
              {/* Zoom in/out */}
              <div className="z-10 items-center text-center bg-white rounded-md border border-slate-700 fixed right-[350px] hidden lg:flex top-[100px]">
                <Button
                  onClick={() => {
                    setZoom((prevZoom) => Math.min(3.25, prevZoom + 0.25));
                  }}
                  type="button"
                  className="px-[10px] py-[5px] border-r border-slate-700"
                >
                  <Icon type="zoom-in" className="size-[18px]" />
                </Button>
                <Button
                  onClick={() => {
                    setZoom((prevZoom) => Math.max(0.25, prevZoom - 0.25));
                  }}
                  type="button"
                  className="px-[10px] py-[5px]"
                >
                  <Icon type="zoom-out" className="size-[18px]" />
                </Button>
              </div>

              <div className="flex flex-col md:flex-row items-start justify-between min-h-[calc(100vh-77px)]">
                <div className="flex-column md:flex-row w-full mt-[100px] xl:mt-0">
                  {/* First Div */}
                  {loading ? (
                    <div className="flex items-center justify-center w-full h-screen">
                      <Spinner className="size-20 !border-t-slate-500" />
                    </div>
                  ) : (
                    <div
                      className={`flex flex-col items-center justify-center pdf-container relative w-full ${documentContainerHeightClass}`}
                      ref={pdfContainerRef}
                    >
                      {/* PDF */}
                      <div className="cursor-zoom-in">
                        {mimeType?.startsWith("image/") && (
                          <img
                            src={fileUrl}
                            alt="Document"
                            style={{ maxWidth: "100%" }}
                          />
                        )}
                        {mimeType === "application/pdf" &&
                          pdfUrls.length > 0 &&
                          pdfUrls.map((pdfUrl, index) => {
                            const visibleClass = pdf === index ? "" : "hidden";

                            if (pdfContainerRef.current) {
                              const container =
                                pdfContainerRef.current as HTMLElement;
                              container.scrollTop = (647 * zoom) / 2;
                              container.scrollLeft = (600 * zoom) / 2;
                            }

                            return (
                              <div
                                className={`absolute ${documentDistanceClass} md:top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4`}
                                key={`pdf_preview_${index + 1}`}
                              >
                                <div
                                  className={`${visibleClass}`}
                                  style={{
                                    transform: `scale(${zoom})`,
                                    transformOrigin: "top center",
                                  }}
                                >
                                  <TransformWrapper
                                    initialScale={1}
                                    initialPositionX={0}
                                    initialPositionY={0}
                                    maxScale={3}
                                  >
                                    <TransformComponent>
                                      {pdf === index && (
                                        <Document
                                          file={pdfUrl}
                                          ref={documentRef}
                                          loading={<p>{labels.loading}</p>}
                                          error={
                                            <p>{labels.errorWhileLoadingPdf}</p>
                                          }
                                        >
                                          <Page
                                            pageNumber={page}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                            width={documentWidth}
                                            loading={<p>{labels.loading}</p>}
                                            error={
                                              <p>
                                                {labels.errorWhileLoadingPdf}
                                              </p>
                                            }
                                          />
                                        </Document>
                                      )}
                                    </TransformComponent>
                                  </TransformWrapper>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}

                  {/* Second Div */}
                  {(action !== "" || lawyerSigning) && "validate" !== action ? (
                    <>
                      {!signedDocumentStatus &&
                      !["waitingForPayment", "closed"].includes(currentStep) ? (
                        <div className="flex-column lg:w-[calc(100%-270px)] w-full h-[161px] bg-white absolute left-0 bottom-0 sm:px-5 xl">
                          <div className="max-w-[930px] mx-auto mt-[20px]">
                            <div className="body-sm mx-auto pt-6">
                              {labels.documentViewLegalMentions}
                            </div>
                            <div className="flex justify-between mx-auto pt-4">
                              <div>
                                <label
                                  className={`flex relative body-md items-center custom-checkbox-container ${errorTextClass}`}
                                >
                                  <Field
                                    type="checkbox"
                                    name="documentRead"
                                    id="documentRead"
                                    className={`custom-checkbox ${hasError ? "error" : ""}`}
                                    onChange={() => {
                                      setHasError(false);
                                      setReadAllDocumentsChecked(
                                        !readAllDocumentsChecked,
                                      );
                                      formik.setFieldValue(
                                        "documentRead",
                                        !formik.values.documentRead,
                                      );
                                    }}
                                  />
                                  {labels.affirmationToHaveReadTheDocuments}
                                </label>
                                {hasError ? (
                                  <span className="text-[10px] max-w-[293px] text-ea-red absolute">
                                    {labels.requiredFieldText}
                                  </span>
                                ) : null}
                              </div>
                              <div>
                                <label
                                  className={`flex relative body-md items-center custom-checkbox-container ${errorTextClass}`}
                                >
                                  <Field
                                    type="checkbox"
                                    name="acceptedTerms"
                                    id="acceptedTerms"
                                    className={`custom-checkbox ${hasError ? "error" : ""}`}
                                    onChange={() => {
                                      setHasError(false);
                                      formik.setFieldValue(
                                        "acceptedTerms",
                                        !formik.values.acceptedTerms,
                                      );
                                      setAcceptedCondition(!acceptedCondition);
                                    }}
                                  />
                                  {labels.acceptTermsAndConditions}
                                </label>
                                {hasError ? (
                                  <span className="text-[10px] max-w-[293px] text-ea-red absolute">
                                    {labels.requiredFieldText}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {isActCreator ? (
                            // Displayed when the document has been signed
                            <div className="flex w-full md:w-[calc(100%-280px)] h-[161px] bg-white absolute left-0 bottom-0 md:px-5 items-center">
                              <div className="flex max-w-[930px] mx-auto">
                                <Icon
                                  type="circle-check"
                                  color="#00B900"
                                  className="size-[24px]"
                                />
                                <p className="body-lg h-full ml-6">
                                  {labels.signedActByAllPartiesLabel}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex w-full md:w-[calc(100%-280px)] h-[161px] bg-white absolute left-0 bottom-0 md:px-5 items-center">
                              <div className="flex max-w-[930px] mx-auto">
                                <Icon
                                  type="circle-check"
                                  color="#00B900"
                                  className="size-[24px] mt-4"
                                />
                                <div>
                                  <p className="body-lg ml-6 h-[30px]">
                                    {labels.signedActAndAnnexesLabel}
                                  </p>
                                  <p className="body-lg ml-6 h-[30px]">
                                    {labels.downloadDocumentOnFinalizedActLabel}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </div>

                {/* Third Div */}
                <div className="relative w-full md:w-[290px] pt-[69px] pb-[25px] pr-[30px] pl-[37px] bg-white flex-col justify-between items-center h-[calc(100dvh-77px)] hidden lg:flex">
                  {loading && (
                    <div className="absolute top-1/2 left-1/2 -translate-x-2/4  -translate-y-2/4">
                      <Spinner className="size-10" dark={true} />
                    </div>
                  )}
                  <div
                    className={`overflow-auto mb-2 scrollable-container ${borderBottom} h-[calc(100%-136px)]`}
                  >
                    {fileUrl.length > 0 && mimeType.startsWith("image/") && (
                      <img src={fileUrl} alt="Document" className="mb-4" />
                    )}
                    {/* Thumbnails */}
                    {mimeType === "application/pdf" &&
                      pdfUrls.length > 0 &&
                      pdfUrls.map((pdfUrl, pdfIndex) => {
                        return (
                          <Document
                            file={pdfUrl}
                            key={`pdf_${pdfIndex + 1}`}
                            onLoadSuccess={(args) =>
                              onDocumentLoadSuccess(args, pdfUrl)
                            }
                            loading={<p>{labels.loading}</p>}
                            error={<p>{labels.errorWhileLoadingPdf}</p>}
                          >
                            <div className="flex">
                              {!loading && (
                                <h2 className="mr-4 font-bold body-lg">
                                  {labels.doc} {pdfIndex + 1}
                                </h2>
                              )}
                              <div className="pt-1 mr-[30px]">
                                {!loading &&
                                  Array.from(
                                    Array(numPagesMap[pdfUrl]).keys(),
                                  ).map((_, index) => {
                                    const pageBorderClass =
                                      pdf === pdfIndex && index + 1 === page
                                        ? "border-red-700 ring-2 ring-red-700"
                                        : "border-slate-200";

                                    return (
                                      <div
                                        id={`pdf_${pdfIndex}_page_${index + 1}`}
                                        key={`page_${index + 1}`}
                                      >
                                        <Page
                                          pageNumber={index + 1}
                                          width={140}
                                          height={197}
                                          onClick={() => {
                                            handleChangingPage(
                                              pdfIndex,
                                              index,
                                              pdfUrl,
                                            );
                                          }}
                                          className={`${pageBorderClass} cursor-pointer mb-4 border`}
                                          renderTextLayer={false}
                                          renderAnnotationLayer={false}
                                          loading={<p>{labels.loading}</p>}
                                          error={
                                            <p>{labels.errorWhileLoadingPdf}</p>
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </Document>
                        );
                      })}
                  </div>
                  {hasActionButton &&
                  signBookStatus?.status !== "PAID" &&
                  Number(order) === 3 &&
                  isSecure ? (
                    <div className="p-3">
                      <p className="text-center body-md mb-2">
                        <span className="font-bold mr-1">{labels.itsUpTo}</span>
                        <span className="font-bold mr-1">{labels.master}</span>
                        <span className="font-bold mr-1">{signatoryName}</span>
                        <span className="font-bold mr-1">
                          {labels.isSigning}
                        </span>
                      </p>
                      <div className="max-w-[105px] m-auto">
                        <ProgressBar
                          currentStep={Number(order) - 1}
                          maxSteps={4}
                        />
                      </div>
                      <div className="flex justify-between mt-8">
                        <Button
                          type="submit"
                          className="btn btn-base btn-primary w-full body-lg flex items-center justify-center mr-3 px-3"
                          disabled={isDisabled || loading}
                        >
                          {loading && <Spinner className="size-3 mr-1" />}
                          {labels.sign}
                        </Button>
                        <Button
                          type="button"
                          disabled={isDisabled || loading}
                          className={`btn btn-base btn-secondary flex justify-end items-center body-lg w-full whitespace-nowrap px-3`}
                          onClick={() => {
                            dispatch(
                              setModalData({
                                ...data,
                                lawyerName: signatoryName,
                                lawyerCode: lawyerCode,
                              }),
                            );
                            dispatch(showModal("lawyerSignatureConfirmation"));
                          }}
                        >
                          {labels.signOnAnotherDevice}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    hasActionButton &&
                    signBookStatus?.status !== "PAID" &&
                    currentStep !== "waitingForPayment" &&
                    !paymentState && (
                      <div>
                        <div className="max-w-[250px] flex-column px-[15px]">
                          <Button
                            type="submit"
                            className="btn-primary w-full py-[6px] px-[30px] rounded-[8px] flex items-center justify-center mb-[30px]"
                            disabled={isDisabled || loading}
                          >
                            {labels.sign}
                          </Button>
                        </div>
                      </div>
                    )
                  )}
                  {(signedDocumentStatus ||
                    currentStep === "waitingForPayment") &&
                    signBookStatus?.status !== "PAID" &&
                    !paymentState &&
                    isActCreator && (
                      <div>
                        <div className="max-w-[250px] flex-column px-[15px]">
                          <Button
                            className="btn-primary w-full py-[6px] px-[30px] rounded-[8px] flex items-center justify-center mb-[30px]"
                            disabled={loading}
                            onClick={(event) => {
                              event.preventDefault();
                              handlePayment();
                            }}
                          >
                            {labels.pay}
                          </Button>
                        </div>
                      </div>
                    )}
                </div>

                {hasActionButton && (
                  <div className="flex lg:hidden absolute mx-auto r-0 l-0 bottom-0 w-full bg-white">
                    <div className="w-full flex-column px-[15px] my-4">
                      <Button
                        type="submit"
                        className="btn-primary w-full py-[6px] px-[30px] rounded-[8px] flex items-center justify-center"
                        disabled={isDisabled || loading}
                      >
                        {labels.sign}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default DocumentPreview;
