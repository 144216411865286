import axios from "axios";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { PDFDocument } from "pdf-lib";
import { toast } from "react-toastify";

import {
  actTypeNameMap,
  axiosErrorMessages,
  labels,
  toastOptionsError,
} from "@constants";

import { PathMap } from "@types";

import { frenchPhoneNumberRegex } from "@utils/regex";

/**
 * Sorts the data based on object key and order
 *
 * @param data       The data to be sorted
 * @param order      The order of the sorting
 * @param key        The key to sort the data
 * @param dataType   The type of data to sort
 * @param dataFormat The format of the data
 * @returns          The sorted data
 */
const sortData = (
  data: Record<string, any>[],
  order: string,
  key: string,
  dataType: string = "",
  dataFormat: string = "",
) => {
  return data.sort((a, b) => {
    let rowA = a[key];
    let rowB = b[key];

    if (dataType === "date") {
      rowA = formatDate(rowA, dataFormat);
      rowB = formatDate(rowB, dataFormat);
    }

    const orderModifier = order === "asc" ? 1 : -1;

    return (rowA < rowB ? -1 : 1) * orderModifier;
  });
};

/**
 * Formats the date string to a Date object
 *
 * @param dateString The date string to be formatted
 * @param format     The format of the date
 * @returns          The formatted date
 */
const formatDate = (dateString: string, format = "") => {
  switch (format) {
    case "shortDate":
      return formatDateShort(dateString);
    case "longDate":
      return formatDateLong(dateString);
    default:
      return new Date(dateString);
  }
};

/**
 * Formats the date string to a Date object
 *
 * @param dateString The date string to be formatted
 * @returns          The formatted date
 */
const formatDateShort = (dateString: string) => {
  const [day, month, year] = dateString.split("/").map(Number);

  return new Date(year, month - 1, day);
};

/**
 * Formats the date string to a Date object
 *
 * @param dateString The date string to be formatted
 * @returns          The formatted date
 */
const formatDateLong = (dateString: string) => {
  const parts = dateString.split(" ");
  const dateParts = parts[0].split("/").map(Number);
  const timeParts = parts[1].split(":").map(Number);

  const [day, month, year] = dateParts;
  const [hours, minutes] = timeParts;

  // Return a new Date object
  return new Date(year, month - 1, day, hours, minutes);
};

/**
 * Formats the label with the provided arguments.
 *
 * @param template The string template.
 * @param args     Arguments to be inserted.
 * @returns        The formatted label.
 */
const formatLabel = (template: string, ...args: string[]): string => {
  return template.replace(
    /\$(\d+)/g,
    (_, index) => args[parseInt(index) - 1] || "",
  );
};

/**
 * Generates a unique string that's useful for creating IDs in the frontend.
 *
 * @returns The generated UUID.
 */
const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Returns the date after some months period.
 *
 * @param date      The date string to be formatted
 * @param months    The number of months to add.
 * @returns         The future date in type Date.
 */
const getDateAfterPeriod = (date: Date, months: number) => {
  return new Date(date.setMonth(date.getMonth() + months));
};

/**
 * Converts bytes to megabytes
 *
 * @param bytes The number of bytes to convert
 * @returns     The converted value in megabytes
 */
const bytesToMegabytes = (bytes: number) => {
  return bytes / 1024 / 1024;
};

/**
 * Gets the value at a given path from an object.
 *
 * @param object       The object to query.
 * @param path         The path of the property to get, either as a dot-separated string or an array of keys.
 * @param defaultValue The value returned if the resolved value is undefined.
 * @returns            The resolved value.
 */
const get = (
  object: Object,
  path: string | Array<string>,
  defaultValue?: any,
): any => {
  const keys: Array<string> = Array.isArray(path) ? path : path.split(".");
  return (
    keys.reduce((acc: any, key: string) => {
      if (acc && acc[key] !== undefined) {
        return acc[key];
      } else {
        return defaultValue;
      }
    }, object) || defaultValue
  );
};

/**
 * MIME types for different file extensions.
 * @type {Object}
 * @property {string} pdf - application/pdf
 * @property {string} png - image/png
 * @property {string} jpeg - image/jpeg
 * @property {string} jpg - image/jpeg
 * @property {string} bmp - image/bmp
 */
const mimeTypes: { [key: string]: string } = {
  pdf: "application/pdf",
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  bmp: "image/bmp",
};

/**
 * Gets the MIME type of a file based on its extension.
 *
 * @param fileName The name of the file.
 * @returns        The MIME type of the file.
 */
const getMimeType = (fileName: string): string | null => {
  const fileExtension = fileName.split(".").pop()?.toLowerCase();
  return fileExtension ? mimeTypes[fileExtension] || null : null;
};

/**
 * Merges the PDFs into a single PDF.
 *
 * @param pdfUrls   The URLs of the PDFs to merge.
 * @returns         The merged PDF.
 */
async function mergePDFs(pdfUrls: string[]) {
  const mergedPdf = await PDFDocument.create();

  for (const pdfUrl of pdfUrls) {
    const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const copiedPages = await mergedPdf.copyPages(
      pdfDoc,
      pdfDoc.getPageIndices(),
    );
    copiedPages.forEach((page) => mergedPdf.addPage(page));
  }

  return await mergedPdf.save();
}

/**
 * Downloads the merged PDF.
 *
 * @param pdfUrls   The URLs of the PDFs to merge.
 * @param fileName  The name of the downloaded file. Default is "documents.pdf".
 */
async function downloadPDF(
  pdfUrls: string[],
  fileName: string = "documents.pdf",
) {
  const mergedPdfBytes = await mergePDFs(pdfUrls);
  const mergedPdfBlob = new Blob([mergedPdfBytes], {
    type: "application/pdf",
  });
  const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);
  const link = document.createElement("a");
  link.href = mergedPdfUrl;

  // Extracting filename from the last URL
  const lastUrl = pdfUrls[pdfUrls.length - 1];
  const urlParts = lastUrl.split("/");
  const uploadedFileName = urlParts[urlParts.length - 1];

  // Setting download attribute with the uploaded file name or if it doesn't exist, the default file name
  link.setAttribute("download", fileName || uploadedFileName);
  link.click();
  link.remove();
}

/**
 * Downloads the file.
 *
 * @param fileBlob   The Blob of the file to download.
 * @param fileName   The name of the downloaded file.
 */
function downloadFile(fileBlob: Blob, fileName: string) {
  const fileUrl = URL.createObjectURL(fileBlob);
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(fileUrl); // Clean up
}

/**
 * Prints the file.
 *
 * @param fileBlob   The Blob of the file to download.
 * @param fileName   The name of the downloaded file.
 */
function printFile(fileBlob: Blob, fileName: string) {
  const fileUrl = URL.createObjectURL(fileBlob);
  const iframe = document.createElement("iframe");
  iframe.src = fileUrl;
  iframe.style.display = "none";
  document.body.appendChild(iframe);

  iframe.onload = function () {
    if (iframe.contentWindow) {
      iframe.contentWindow.print();
      iframe.contentWindow.onafterprint = function () {
        document.body.removeChild(iframe);
        URL.revokeObjectURL(fileUrl);
      };
    }
  };
}

/**
 * Merges the PDFs into a single PDF and prints it.
 *
 * @param pdfUrls   The URLs of the PDFs to merge.
 */
async function mergeAndPrint(pdfUrls: string[]) {
  const mergedPdfBytes = await mergePDFs(pdfUrls);
  const mergedPdfBlob = new Blob([mergedPdfBytes], {
    type: "application/pdf",
  });
  const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);

  const iframe = document.createElement("iframe");
  iframe.src = mergedPdfUrl;
  iframe.style.display = "none";
  document.body.appendChild(iframe);

  iframe.onload = function () {
    if (iframe.contentWindow) {
      iframe.contentWindow.print();
      iframe.contentWindow.onafterprint = function () {
        document.body.removeChild(iframe);
        URL.revokeObjectURL(mergedPdfUrl);
      };
    }
  };
}

/**
 * Create a PDF from the table and export it for printing.
 */
const exportPdf = async (title: string) => {
  const doc = new jsPDF({ orientation: "portrait" });

  // Set the title text at the top
  doc.setFontSize(16);
  doc.text(title, doc.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });

  // Set margins to create space at the bottom
  doc.setDrawColor(0);
  // Pdf draw property
  autoTable(doc, {
    html: "#historyTable",
    styles: {
      fontSize: 10,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
    },
    margin: { top: 20, bottom: 40 },
    headStyles: { textColor: [80, 80, 80], lineColor: [0, 0, 0] },
    showHead: "everyPage",
    columnStyles: {
      0: { cellWidth: 35, cellPadding: 3 },
      1: { cellWidth: 110, cellPadding: 3 },
      2: { cellWidth: 35, cellPadding: 3 },
    },
  });

  doc.autoPrint();
  const hiddFrame = document.createElement("iframe");
  hiddFrame.style.position = "fixed";
  // "visibility: hidden" would trigger safety rules in some browsers like safari，
  // in which the iframe display in a pretty small size instead of hidden.
  hiddFrame.style.width = "1px";
  hiddFrame.style.height = "1px";
  hiddFrame.style.opacity = "0.01";
  const isSafari = /^((?!chrome|android).)*safari/i.test(
    window.navigator.userAgent,
  );
  if (isSafari) {
    // fallback in safari
    hiddFrame.onload = () => {
      try {
        hiddFrame.contentWindow?.document.execCommand(
          "print",
          false,
          undefined,
        );
      } catch (e) {
        hiddFrame.contentWindow?.print();
      }
    };
  }
  // Use output with type "blob" and create URL from Blob
  const blob = doc.output("blob");
  hiddFrame.src = URL.createObjectURL(blob);
  document.body.appendChild(hiddFrame);
};

/**
 * Validates the email address.
 *
 * @param value     The email address to validate.
 * @returns         The error message if the email is invalid.
 */
const validateEmail = (value: string) => {
  let error;

  if (!value) {
    error = formatLabel(labels.requiredField, labels.email);
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = labels.invalidEmail;
  }

  return error;
};

/**
 * Luhn algorithm for SIREN number validation
 *
 * @param num       The SIREN number to validate.
 * @returns         The result of the Luhn algorithm.
 */
const luhnCheck = (num: string) => {
  const arr = `${num}`
    .split("")
    .reverse()
    .map((x) => Number.parseInt(x));
  const lastDigit: number = arr.shift() || 0;
  let sum = arr.reduce(
    (acc, val, i) =>
      i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val),
    0,
  );
  sum += lastDigit;
  return sum % 10 === 0;
};

/**
 * Validates the SIREN number.
 *
 * @param sirenNumber The SIREN number to validate.
 * @returns           The error message if the SIREN number is invalid.
 */
const validateSiren = (sirenNumber: string) => {
  let error;
  if (sirenNumber.trim().length > 0) {
    if (!/^\d{9}$/.test(sirenNumber)) {
      error = labels.charLimitSiren;
    } else if (!luhnCheck(sirenNumber)) {
      error = labels.invalidSirenNumber;
    }
  }
  return error;
};

/**
 * Validates phone number.
 *
 * @param phoneNumber The phone number to validate.
 * @returns           The error message if the phone number is invalid.
 */
const validatePhone = (phoneNumber: string) => {
  let error;

  if (!phoneNumber) {
    error = formatLabel(labels.requiredField, labels.phone);
  } else if (!frenchPhoneNumberRegex.test(phoneNumber)) {
    error = formatLabel(labels.compliantField, labels.phone);
  }

  return error;
};

/**
 * Resets an object's values to empty strings, except for the keys to preserve.
 *
 * @param object            The object to reset.
 * @param keysToPreserve    The keys to preserve.
 * @returns                 The reset object.
 */
const resetObject = (
  object: Record<string, any>,
  keysToPreserve: string[] = [],
) => {
  return Object.fromEntries(
    Object.entries(object).map(([key]) => [
      key,
      keysToPreserve.includes(key) ? object[key] : "",
    ]),
  );
};

/**
 * Gets the path map based on the action type.
 *
 * @param actType  The action type.
 * @param publicId The ID of the action.
 * @returns        The path map.
 */
const getPathMap = (
  actType: string | undefined,
  publicId: string | undefined = undefined,
) => {
  if (!actType) {
    return {};
  }

  const isNumericId = !isNaN(Number(publicId?.split("-")[0]));

  let pathMap = {};

  switch (actType) {
    case "birth":
    case "divorce":
    case "archived":
    case "expiring":
      pathMap = {
        1: {
          label: labels.information,
          url: isNumericId ? `/${publicId}/information` : "/new",
        },
        2: {
          label: labels.partiesAndAdvocates,
          url: isNumericId ? `/${publicId}/parties` : null,
        },
        3: {
          label: labels.documents,
          url: isNumericId ? `/${publicId}/documents` : null,
        },
        4: {
          label: labels.signaturesLabel,
          url: isNumericId ? `/${publicId}/signatures` : null,
        },
      };
      break;

    case "digital":
      pathMap = {
        1: {
          label: labels.information,
          url: isNumericId ? `/${publicId}/information` : "/new",
        },
        2: {
          label: labels.partiesAndAdvocates,
          url: isNumericId ? `/${publicId}/parties` : null,
        },
        3: {
          label: labels.documents,
          url: isNumericId ? `/${publicId}/documents` : null,
        },
      };
      break;
    case "convention":
      pathMap = {
        1: {
          label: labels.information,
          url: publicId ? `/${publicId}/information` : "/new",
        },
        2: {
          label: labels.clients,
          url: isNumericId ? `/${publicId}/clients` : null,
        },
        3: {
          label: labels.convention,
          url: isNumericId ? `/${publicId}/convention` : null,
        },
        4: {
          label: labels.signaturesLabel,
          url: isNumericId ? `/${publicId}/signatures` : null,
        },
      };
      break;
    default:
      break;
  }

  return pathMap;
};

/**
 * Gets the step from the path map.
 *
 * @param locationPath  The location path.
 * @param pathMap       The path map.
 * @returns             The step number.
 */
const getStepFromPathMap = (locationPath: string, pathMap: PathMap): number => {
  const step = Object.keys(pathMap).find((key) => {
    if (!pathMap[parseInt(key)]?.url) {
      return false;
    }

    return pathMap[parseInt(key)]?.url.endsWith(locationPath);
  });

  return Number(step);
};

/**
 * No operation function.
 */
const noop = () => {};

/**
 * Checks if two dates are X days apart.
 *
 * @param date1 The first date.
 * @param date2 The second date.
 * @param days  The number of days.
 * @returns     Whether the dates are X days apart.
 */
const areApart = (date1: Date, date2: Date, days: number) => {
  // Convert the dates to their millisecond representation
  const date1Ms = new Date(date1).getTime();
  const date2Ms = new Date(date2).getTime();

  // Find the difference in milliseconds
  const diffMs = Math.abs(date1Ms - date2Ms);

  // Convert milliseconds to days
  const diffDays = diffMs / (1000 * 60 * 60 * 24);

  // Check if the difference is X days or more
  return diffDays >= days;
};

/**
 * Checks if two dates are exactly X days apart.
 *
 * @param date1 The first date.
 * @param date2 The second date.
 * @param days  The number of days.
 * @returns     Whether the dates are exactly X days apart.
 */
const isExactDaysApart = (date1: Date, date2: Date, days: number) => {
  // Convert the dates to their millisecond representation
  const date1Ms = new Date(date1).getTime();
  const date2Ms = new Date(date2).getTime();

  // Find the difference in milliseconds
  const diffMs = Math.abs(date1Ms - date2Ms);

  // Convert milliseconds to days (using Math.round to account for time differences within the day)
  const diffDays = Math.round(diffMs / (1000 * 60 * 60 * 24));

  // Check if the difference is exactly X days
  return diffDays === days;
};

/**
 * Checks if the start date is before the end date.
 *
 * @param startDate The start date.
 * @param endDate   The end date.
 * @returns         The error message if the start date is after the end date.
 */
const isStartDateBeforeEndDate = (
  startDate: Date | string,
  endDate: Date | string,
) => {
  if (
    startDate &&
    endDate &&
    typeof startDate === "object" &&
    typeof endDate === "object"
  ) {
    if (startDate.getTime() > endDate.getTime()) {
      return labels.startDateShouldBeBeforeEndDate;
    }

    return true;
  }

  return false;
};

/**
 * Check if 2 dates are exactly X days apart before today.
 *
 * @param date1 The first date.
 * @param date2 The second date.
 * @param days  The number of days.
 * @returns     Whether the dates are exactly X days apart before today.
 */
const isExactDaysApartBeforeToday = (
  date1: Date | string,
  date2: Date | string,
  days: number,
) => {
  if (
    date1 &&
    date2 &&
    typeof date1 === "object" &&
    typeof date2 === "object"
  ) {
    const today = new Date();
    const todayOnly = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    );

    const isExactDaysApart = (d1: Date, d2: Date, days: number) => {
      const d1Only = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
      const d2Only = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());

      const differenceInTime = Math.abs(d1Only.getTime() - d2Only.getTime());
      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24),
      );

      return differenceInDays === days;
    };

    // Check if either date is in the future
    if (date1 > todayOnly || date2 > todayOnly) {
      return false;
    }

    return (
      isExactDaysApart(date1, todayOnly, days) &&
      isExactDaysApart(date2, todayOnly, days)
    );
  }

  return false;
};

/**
 * Checks if both dates are at least X days before today.
 *
 * @param date1 The first date.
 * @param date2 The second date.
 * @param days  The number of days (X) before today to check.
 * @returns     True if both dates are at least X days before today, false otherwise.
 */
const areDatesAtLeastXDaysBeforeToday = (
  date1: Date | string,
  date2: Date | string,
  days: number,
) => {
  if (
    date1 &&
    date2 &&
    typeof date1 === "object" &&
    typeof date2 === "object"
  ) {
    const today = new Date();
    const todayOnly = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    );

    const xDaysAgo = new Date(
      todayOnly.getFullYear(),
      todayOnly.getMonth(),
      todayOnly.getDate() - days,
    );

    return (
      date1.getTime() <= xDaysAgo.getTime() &&
      date2.getTime() <= xDaysAgo.getTime()
    );
  }

  return false;
};

/**
 * Sets the dates based on the selected value.
 *
 * @param value        The selected value.
 * @param formik       The formik object.
 * @param setStartDate The setStartDate function.
 * @param setEndDate   The setEndDate function.
 */
const setDates = (
  value: string,
  formik: any,
  setStartDate: React.Dispatch<React.SetStateAction<string | Date>>,
  setEndDate: React.Dispatch<React.SetStateAction<string | Date>>,
) => {
  if (value) {
    const fullDateRange = value.split(" - ");
    // If it's selected only one date in the range and the select option is closed, set the end date to today
    if (
      (fullDateRange[1] && fullDateRange[1].length === 0) ||
      (fullDateRange.length === 2 && fullDateRange[1].length === 0) ||
      fullDateRange.length === 1
    ) {
      // Get the first date string
      const startDateString = value.split(" - ")[0];
      const [day, month, year] = startDateString.split("/");
      // Create a new Date object with the components
      const startDate = new Date(`${year}-${month}-${day}`);

      setStartDate(startDate);
      formik.setFieldValue("startDate", startDate);
      // Set the end date to today
      const endDate = new Date();
      setEndDate(endDate);

      formik.setFieldValue("endDate", endDate);
      isStartDateBeforeEndDate(startDate, endDate);
    }
  }
};

/**
 * Adds query parameters to the URL.
 *
 * @param url    The URL to add the query parameters to.
 * @param params The query parameters to add.
 * @param hash   The hash to add to the URL.
 * @returns      The URL with the query parameters.
 */
const addQueryParams = (
  url: string,
  params: Record<string, string | undefined> = {},
  hash?: string,
) => {
  const hashValue = hash ? `#${hash}` : "";
  const urlParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value && "" !== value) {
      urlParams.append(key, value);
    }
  });

  if (urlParams.toString().length === 0) {
    return `${url}${hashValue}`;
  }

  const paramsSymbol = url.includes("?") ? "&" : "?";

  return `${url}${paramsSymbol}${urlParams.toString()}${hashValue}`;
};

/**
 * Capitalizes the first letter of text.
 *
 * @param text The text to capitalize.
 * @returns    The capitalized word.
 */
const capitalize = (text: string) => {
  if (text.length === 0) {
    return text;
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Formats the timestamp to a readable format.
 *
 * @param timestamp The timestamp to format.
 * @param hasTime   Whether to include the time.
 * @param shortYear Whether to include the short year.
 * @returns         The formatted timestamp.
 */
const formatTimestamp = (
  timestamp: string | number,
  hasTime: boolean = true,
  shortYear: boolean = false,
) => {
  const date = new Date(timestamp);

  const options: Intl.DateTimeFormatOptions = {
    timeZone: "Europe/Paris",
    day: "2-digit",
    month: "2-digit",
    year: shortYear ? "2-digit" : "numeric",
    ...(hasTime && {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
  };

  return date.toLocaleString("fr-FR", options);
};

/**
 * Formats the timestamp for request.
 *
 * @param timestamp The timestamp to format.
 * @returns         The formatted timestamp.
 */
const formatTimestampForRequest = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

/**
 * Checks if a value is empty.
 *
 * @param value The value to check.
 * @returns     Whether the value is empty.
 */
const isEmpty = (value: any): boolean => {
  if (value === "") {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  return (
    typeof value === "object" &&
    value !== null &&
    Object.keys(value).length === 0
  );
};

/**
 * Removes empty values from an object.
 *
 * @param obj The object to remove empty values from.
 * @returns   The object without empty values.
 */
const removeEmptyValues = (obj: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (!isEmpty(value)) {
        result[key] = value;
      }
    }
  }

  return result;
};

/**
 * Checks if the date is in the past.
 *
 * @param value The date to check.
 * @returns     Whether the date is in the past.
 */
const isPastDate = (value: string) => {
  const parts = value.split("/");
  let timestamp;

  // Ensure the date has three parts (DD/MM/YYYY)
  if (parts.length !== 3) {
    timestamp = new Date(value).getTime();
  } else {
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    timestamp = new Date(year, month, day).getTime();
  }

  const currentDate = new Date();

  // Check if the date is in the past or today
  return timestamp <= currentDate.getTime();
};

/**
 * Formats the date of birth.
 *
 * @param date The date of birth to format.
 * @returns    The formatted date of birth.
 */
const formatDateBirth = (date: Date) => {
  const datePart = date.toISOString().slice(0, 10);

  const transformedDate = datePart.replace(/-/g, "/");
  if (transformedDate.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
    const [year, month, day] = transformedDate.split("/");
    return `${day}/${month}/${year}`;
  } else {
    // If already in 'DD/MM/YYYY' format , return as-is
    return transformedDate;
  }
};

/**
 * Get the act link based on the act data.
 *
 * @param actData     The act data.
 * @param returnPath  The return path.
 * @returns           The act link.
 */
const getActLink = (
  actData: Record<string, string>,
  returnPath: string = "/",
) => {
  const { type, subType, publicId, status, signingStatus } = actData;

  const mappedType = actTypeNameMap[type];
  const mappedTypeDcm = actTypeNameMap[subType];
  const linkType = undefined !== mappedTypeDcm ? mappedTypeDcm : mappedType;
  const actIsNew =
    ["created", "shared"].includes(status) &&
    signingStatus === "BEFORE_SIGNING";

  return actIsNew
    ? `/acts/${linkType}/${publicId}/information`
    : addQueryParams(`/acts/${linkType}/act-details/${publicId}`, {
        returnPath: returnPath,
      });
};

/**
 * Fetches and downloads the template.
 *
 * @param guideTemplateName The guide template name.
 */
const fetchAndDownloadTemplate = async (guideTemplateName: string) => {
  try {
    const response = await axios.get(
      `/api/v1/documents/templates/${guideTemplateName}`,
      {
        responseType: "arraybuffer",
      },
    );

    const contentType = response.headers["content-type"];
    const fileBlob = new Blob([response.data], { type: contentType });
    const fileUrl = URL.createObjectURL(fileBlob);

    // Create an invisible link element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = `${guideTemplateName}.pdf`; // Set the desired file name

    // Append the link to the body (not visible to the user)
    document.body.appendChild(link);

    // Programmatically trigger the download without opening a new tab
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(fileUrl);
  } catch (error: any) {
    toast.error(axiosErrorMessages[error.message], toastOptionsError);
  }
};

/**
 * Convert a Base64 string with a data URL prefix to a File object.
 *
 * @param base64String  The Base64 encoded string with the data URL prefix.
 * @param fileName      The name of the resulting file.
 * @param mimeTypeMap   A map of MIME types to file extensions.
 * @returns             A File object that can be used in FormData or other file handling APIs.
 * @throws              An error if the MIME type is not supported or the string is malformed.
 */
const base64ToFile = (
  base64String: string,
  fileName: string,
  mimeTypeMap: Record<string, string> = {
    "image/png": "png",
    "image/jpeg": "jpg",
    "image/bmp": "bmp",
  },
) => {
  // Split the Base64 string into the data URL prefix and the actual Base64 data
  const [prefix, base64Data] = base64String.split(",");

  if (!prefix || !base64Data) {
    toast.error("Invalid Base64 string", toastOptionsError);
    return null;
  }

  // Extract the MIME type from the prefix
  const mimeTypeMatch = prefix.match(/data:(.*?);base64/);
  if (!mimeTypeMatch || mimeTypeMatch.length < 2 || !mimeTypeMatch[1]) {
    toast.error("MIME type could not be detected", toastOptionsError);
    return null;
  }

  const mimeType = mimeTypeMatch[1];
  const validMimeTypes = Object.keys(mimeTypeMap);

  if (!validMimeTypes.includes(mimeType)) {
    toast.error("Unsupported MIME type", toastOptionsError);
    return null;
  }

  const extension = mimeTypeMap[mimeType];

  // Decode the Base64 data
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  // Convert each character to a byte
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Convert the byte array to a Uint8Array
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the Uint8Array
  const blob = new Blob([byteArray], { type: mimeType });

  // Create and return a File object
  return new File([blob], `${fileName}.${extension}`, { type: mimeType });
};

/**
 * Handle session expiration by removing the token data for the actor.
 * @param actorCode The actor code.
 * @returns         void
 * @throws          void
 */
const handleSessionExpiration = (actorCode: string) => {
  const data = localStorage.getItem("tokenData");
  if (!data) {
    return;
  }

  const tokenData = JSON.parse(data);
  const { [actorCode]: _, ...rest } = tokenData;
  localStorage.setItem("tokenData", JSON.stringify(rest));
};

/**
 * Refresh the access token and the refresh token.
 * @param processNumber          The process number.
 * @param actorCode              The actor code.
 * @param tokenForRefresh        The token for refresh.
 * @param accessTokenExpiresIn   The access token expiry time.
 * @param refreshTokenExpiresIn  The refresh token expiry time.
 * @param setAccessToken         The setAccessToken function.
 * @param setTokenForRefresh     The setTokenForRefresh function.
 * @returns                      void
 * @throws                       void
 */
const refreshToken = async (
  processNumber: string,
  actorCode: string,
  tokenForRefresh: string,
  accessTokenExpiresIn: string,
  refreshTokenExpiresIn: string,
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>,
  setTokenForRefresh: React.Dispatch<React.SetStateAction<string | null>>,
  refreshUrl: string,
) => {
  try {
    const response = await axios.post(refreshUrl, {
      refreshToken: tokenForRefresh,
    });
    setTokenData(actorCode, response.data);
    const currentTime = Date.now();

    const accessTokenExpiryTime =
      currentTime + Number(accessTokenExpiresIn) * 1000;
    setTokenData(actorCode, {
      accessTokenExpiryTimestamp: String(accessTokenExpiryTime),
    });
    const refreshTokenExpiryTime =
      currentTime + Number(refreshTokenExpiresIn) * 1000;
    setTokenData(actorCode, {
      refreshTokenExpiryTimestamp: String(refreshTokenExpiryTime),
    });

    setAccessToken(response.data.accessToken);
    setTokenForRefresh(response.data.refreshToken);
  } catch (error: any) {
    toast.error(axiosErrorMessages[error.message], toastOptionsError);
  }
};

/**
 * Set token data for the actor.
 * @param actorCode The actor code.
 * @param data      The data to set.
 * @returns         void
 */
const setTokenData = (actorCode: string, data: any) => {
  const tokenData: any = localStorage.getItem("tokenData");
  let tokenDataJson: Record<string, any> = {};
  if (tokenData) {
    tokenDataJson = JSON.parse(tokenData);
  }
  const actorData = tokenDataJson[actorCode] || {};
  tokenDataJson[actorCode] = { ...actorData, ...data };
  localStorage.setItem("tokenData", JSON.stringify(tokenDataJson));
};

/**
 * Get the actor data for the actor code.
 * @param actorCode The actor code.
 * @returns         The actor data.
 */
const getActorData = (actorCode: string) => {
  const tokenData: any = localStorage.getItem("tokenData");

  if (!tokenData) {
    return {};
  }

  const tokenDataJson = JSON.parse(tokenData);
  const actorData = tokenDataJson[actorCode] || {};

  return actorData;
};

/**
 * Get the actor data item for the actor code and key.
 * @param actorCode The actor code.
 * @param key       The key to get the data.
 * @returns         The actor data item.
 */
const getActorDataItem = (actorCode: string, key: string) => {
  const actorData = getActorData(actorCode);
  return actorData[key];
};

/**
 * Generates a range of numbers.
 * @param start    The start number.
 * @returns        The range of numbers.
 */
const range = (start: number, step = 1) => {
  const result = [];
  for (let i = start; i < 100; i += step) {
    result.push(i);
  }
  return result;
};

/**
 * Removes the base URL from a URI.
 * @param uri      The URI.
 * @returns        The URI without the base URL.
 */
const removeBaseUrl = (uri: string) => {
  const baseUrlPattern = /^https?:\/\/[^/]+/;
  const match = uri.match(baseUrlPattern);
  if (match) {
    return uri.slice(match[0].length);
  }
  return uri;
};

/**
 * Filter tokens based on the lawyer ID.
 * @param tokens    The tokens to filter.
 * @param lawyerId  The lawyer ID.
 * @returns         The filtered token.
 */
const filterTokens = (tokens: Record<string, any>[], lawyerId: string) => {
  let token: Record<string, any> | null = null;

  for (let i = 0; i < tokens.length; ++i) {
    const mapAttributes = tokens[i].certificate.json.subject.attributes;

    for (let j = 0; j < mapAttributes.length; ++j) {
      if (Number(mapAttributes[j].value) === Number(lawyerId)) {
        token = tokens[i];
      }
    }
  }

  return token;
};

/**
 * Get the status text based on the status and signing status.
 * @param status         The status.
 * @param signingStatus  The signing status.
 * @returns              The status text.
 */
const getStatusText = (status: string, signingStatus: string) => {
  switch (signingStatus) {
    case "BEFORE_SIGNING":
      if (status === "created") {
        return labels.created;
      }
      if (status === "shared") {
        return labels.sharing;
      }
      if (status === "cancelled") {
        return labels.cancelled;
      }
      break;
    case "AFTER_SIGNING":
      if (status === "waitingForPayment") {
        return labels.waitingForPayment;
      }
      if (status === "closed") {
        return labels.closedAndArchived;
      }
      if (status === "cancelled") {
        return labels.cancelled;
      }
      break;
    default:
      return labels.signing;
  }
};

/**
 * Extracts the file name from a `Content-Disposition` header.
 * @param contentDisposition  The `Content-Disposition` header string.
 * @returns                   The extracted file name, or an empty string if not found.
 */
const extractFileName = (contentDisposition: string) => {
  if (!contentDisposition) {
    return "";
  }
  const fileName =
    contentDisposition.match(/filename\*=UTF-8''(.+)/)?.[1] ??
    contentDisposition.match(/filename="?([^"]+)"?/)?.[1];

  return fileName ? decodeURIComponent(fileName) : "";
};

export {
  sortData,
  formatDate,
  formatDateShort,
  formatDateLong,
  formatLabel,
  formatTimestamp,
  formatTimestampForRequest,
  generateUUID,
  getDateAfterPeriod,
  bytesToMegabytes,
  get,
  getMimeType,
  downloadPDF,
  downloadFile,
  printFile,
  mergeAndPrint,
  validateEmail,
  validateSiren,
  validatePhone,
  resetObject,
  getPathMap,
  getStepFromPathMap,
  noop,
  areApart,
  isExactDaysApart,
  isStartDateBeforeEndDate,
  isExactDaysApartBeforeToday,
  areDatesAtLeastXDaysBeforeToday,
  setDates,
  addQueryParams,
  capitalize,
  isEmpty,
  removeEmptyValues,
  isPastDate,
  formatDateBirth,
  exportPdf,
  getActLink,
  fetchAndDownloadTemplate,
  base64ToFile,
  handleSessionExpiration,
  refreshToken,
  setTokenData,
  getActorData,
  getActorDataItem,
  range,
  removeBaseUrl,
  filterTokens,
  getStatusText,
  extractFileName,
};
