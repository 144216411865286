import axios from "axios";
import { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Filters from "@components/filters/Filters";
import Layout from "@components/layout/Layout";
import ActsTable from "@components/tables/ActsTable";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Notifications from "@ui/Notifications";
import Pagination from "@ui/Pagination";
import PhoneMessage from "@ui/PhoneMessage";
import Spinner from "@ui/Spinner";
import TabButtons from "@ui/TabButtons";

import {
  DAY_IN_MILLISECONDS,
  actTypeNameMap,
  axiosErrorMessages,
  labels,
  toastOptionsError,
} from "@constants";

import useGetStatuses from "@hooks/useGetStatuses";

import {
  selectPageData,
  selectPostState,
  setPostState,
} from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";

import { ActsTableProps, TabListProps } from "@types";

import {
  downloadFile,
  formatTimestamp,
  formatTimestampForRequest,
  generateUUID,
  removeEmptyValues,
} from "@utils";

export const ParamsContext = createContext<Record<string, any>>({});

const Documents = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const tabUrl = location.pathname.split("/").pop();
  const [initializing, setInitializing] = useState<boolean>(true);
  const [fetchingActs, setFetchingActs] = useState<boolean>(true);
  const [exportingActs, setExportingActs] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [tableData, setTableData] = useState<Record<string, ActsTableProps[]>>({
    convention: [],
    divorce: [],
    digital: [],
    birth: [],
    expiring: [],
    closed: [],
  });
  const [tableDataStatuses, setTableDataStatuses] = useState<
    Record<string, any>[]
  >([]);

  const user = useSelector(selectUser);
  const data = useSelector(selectPageData);
  const paymentState = useSelector(selectPostState);
  const [filteredNavigation, setFilteredNavigation] = useState<TabListProps[]>(
    [],
  );

  const [pillMap, setPillMap] = useState<Record<string, boolean>>({
    expiring: false,
  });

  const statuses = useGetStatuses(tableDataStatuses);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tabNames = [
    "convention",
    "divorce",
    "digital",
    "birth",
    "expiring",
    "closed",
  ];

  const tabsList = [
    {
      title: labels.conventionTabLabel,
      id: 0,
      url: "/acts/convention",
      type: "convention",
      label: labels.newConvention,
      isActType: true,
      testId: "convention-tab",
    },
    {
      title: labels.divorceTabLabel,
      id: 1,
      type: "divorce",
      url: "/acts/divorce",
      label: labels.newDivorce,
      isActType: true,
      testId: "divorce-tab",
    },
    {
      title: labels.digitalAct,
      id: 2,
      type: "digital",
      url: "/acts/digital",
      label: labels.newActDigital,
      isActType: true,
      testId: "digital-tab",
    },
    {
      title: labels.lawyersDocumentTabLabel,
      id: 3,
      type: "birth",
      url: "/acts/birth",
      label: labels.newDocument,
      isActType: true,
      testId: "lawyers-document-tab",
    },
    {
      title: labels.participatoryProcedureTabLabel,
      id: 4,
      url: "/acts/participatory",
      hidden: true,
      isActType: true,
      testId: "participatory-procedure-tab",
    },
    {
      title: labels.soonExpiringDocumentsTabLabel,
      id: 5,
      type: "expiring",
      url: "/acts/expiring",
      testId: "expiring-soon-documents-tab",
    },
    {
      title: labels.closedAndArchivedDocumentsTabLabel,
      id: 6,
      type: "closed",
      url: "/acts/closed",
      testId: "closed-and-archived-documents-tab",
    },
  ];

  const getTabParams = (tabType: string) => {
    const dateToday = formatTimestampForRequest(new Date().getTime());
    const dateInOneWeek = formatTimestampForRequest(
      new Date().getTime() + 7 * DAY_IN_MILLISECONDS,
    );

    const defaultParams = {
      companyCode: "FR-CNB",
      ascending: false,
      columnToOrder: "CREATIONDATE",
      types: [],
      page: 1,
    };

    let params;

    switch (tabType) {
      case "divorce":
        params = {
          types: ["eacteavocatwithtimeout", "dcm", "natif"],
          steps: [],
          signBookMDs: { businessSubType: "dcm" },
          expirationDateMin: "",
          expirationDateMax: "",
        };
        break;
      case "digital":
        params = {
          types: ["signatureDigitalPaper", "numerise"],
          steps: [],
          signBookMDs: {},
          expirationDateMin: "",
          expirationDateMax: "",
        };
        break;
      case "birth":
        params = {
          types: ["eacteavocatwithtimeout", "natif"],
          steps: [],
          signBookMDs: {},
          excludeSignBookMDs: { businessSubType: "dcm" },
          expirationDateMin: "",
          expirationDateMax: "",
        };
        break;
      case "expiring":
        params = {
          types: [],
          steps: [
            "created",
            "shared",
            "signing",
            "signedByPlatform",
            "signedByLawyer",
            "signedByParties",
            "waitingForPayment",
            "send",
            "rejected",
          ],
          signBookMDs: {},
          expirationDateMin: dateToday,
          expirationDateMax: dateInOneWeek,
        };
        break;
      case "closed":
        params = {
          types: [],
          steps: ["closed"],
          signBookMDs: {},
          expirationDateMin: "",
          expirationDateMax: "",
        };
        break;
      case "convention":
        params = {
          types: ["econvention", "convention"],
          steps: [],
          signBookMDs: {},
          expirationDateMin: "",
          expirationDateMax: "",
        };
        break;
      default:
        break;
    }

    return { ...defaultParams, ...params, tabType };
  };

  const [defaultTab, setDefaultTab] = useState<string>("");
  const activeTabId =
    filteredNavigation.find((tab) => tab.url === `/acts/${tabUrl}`)?.id || 0;

  const [activeTab, setActiveTab] = useState<number>(activeTabId);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const actType =
    filteredNavigation[activeTab]?.type || defaultTab || "convention";
  const [requestParams, setRequestParams] = useState<Record<string, any>>({});

  const setPage = (page: number) => {
    setRequestParams({
      ...requestParams,
      page,
    });
  };

  const fetchActs = async (param: Record<string, any>, signal: any = null) => {
    const { page, tabType, ...params } = param;

    if (!page) {
      return;
    }

    setFetchingActs(true);

    axios
      .post(
        `/api/v1/signbooks/search?page=${page - 1}`,
        removeEmptyValues(params),
        {
          ...(signal && { signal }),
        },
      )
      .then(async (response) => {
        const results = response.data.results;

        if ("expiring" === tabUrl && results.length > 0) {
          setPillMap({
            expiring: true,
          });
        }

        const newTableData = results.map((result: any) => {
          return {
            id: result.publicId,
            title: result.name,
            creationDate: formatTimestamp(result.creationDate, false, true),
            expirationDate: formatTimestamp(result.expirationDate, false, true),
            state: "",
            maxSteps: 100,
            currentStep: null,
            documentId: "multiple",
            actType: result.type,
            businessSubType: result.businessSubType,
            status: result.status,
            signingStatus: result.signingStatus,
            closedAndArchived: result.status === "closed",
            cancelled:
              result.status === "cancelled" || result.status === "expired",
            creator: result.sbCreatorPublicId,
            wasOpened: result.wasOpened,
            description: result.description,
            mustReadAllDocumentPages: result.mustReadAllDocumentPages,
            expirationDateTimestamp: result.expirationDate,
          };
        });

        setTotalPages(response.data.pages);

        if (actType) {
          setTableData({
            ...tableData,
            [tabType]: newTableData,
          });
        }

        setFetchingActs(false);
        setInitializing(false);
        dispatch(setPostState(false));
      })
      .catch((error) => {
        const { message } = error;

        if (!message || "canceled" === message) {
          return;
        }

        toast.error(axiosErrorMessages[message], toastOptionsError);

        setFetchingActs(false);
        setInitializing(false);
      });
  };

  const exportActs = () => {
    setExportingActs(true);

    const { page, ...params } = requestParams;

    axios
      .post(`/api/v1/signbooks/export`, removeEmptyValues(params))
      .then((response) => {
        const encodingData = "\uFEFF";
        const csvBlob = new Blob([encodingData + response.data], {
          type: "text/csv;charset=utf-8;",
        });
        const dateString = formatTimestampForRequest(new Date().getTime());
        let actType = filteredNavigation[activeTab]?.type || "";
        if (actType === "birth") {
          actType = labels.actNatif;
        } else if (actType === "digital") {
          actType = labels.actDigital;
        }
        const fileName = `${dateString}-${actType}-export.csv`;

        downloadFile(csvBlob, fileName);

        setExportingActs(false);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);

        setExportingActs(false);
      });
  };

  useEffect(() => {
    if (0 === tableData[actType].length) {
      return;
    }

    const statuses = tableData[actType].map((act: any) => {
      return {
        publicId: act.id,
        status: act.status,
        signingStatus: act.signingStatus,
      };
    });

    setTableDataStatuses(statuses);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  // Check if there are expiring documents on page load
  useEffect(() => {
    // If we're on the "expiring" tab we make a request anyway so we'll update
    // the hasExpiring state there instead of making two identical requests
    if (tabUrl === "expiring") {
      return;
    }
    if (requestParams.steps === "waitingForPayment") {
      return;
    }

    axios
      .post(
        `/api/v1/signbooks/search?page=0`,
        removeEmptyValues(getTabParams("expiring")),
      )
      .then(async (response) => {
        const results = response.data.results;

        setPillMap({
          expiring: results.length > 0,
        });
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ("" === user.id || !user.actTypes) {
      return;
    }

    const filterableTabNames = ["convention", "divorce", "digital", "birth"];
    const normalizedFilteredTabNames = user.actTypes.map((tab) => {
      return actTypeNameMap[tab];
    });

    const selectedActTypes = filterableTabNames.filter((tab) => {
      return normalizedFilteredTabNames.includes(tab);
    });

    const selectedNavigation = tabsList
      .filter((tab: Record<string, any>) => {
        if (["expiring", "closed"].includes(tab.type)) {
          return true;
        }

        return selectedActTypes.includes(tab.type);
      })
      .map((tab, index) => {
        return {
          ...tab,
          id: index,
        };
      });
    setDefaultTab(selectedNavigation[0].type as string);
    setFilteredNavigation(selectedNavigation);
    const actToUse =
      tabUrl === "acts" ? (selectedNavigation[0].type as string) : tabUrl ?? "";
    const firstRequestParams = getTabParams(actToUse);

    setRequestParams({
      ...firstRequestParams,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!user.actTypes) {
      return;
    }

    if (requestParams.types === undefined) {
      return;
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    fetchActs(requestParams, abortControllerRef.current.signal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams, paymentState]);

  useEffect(() => {
    if (filteredNavigation[activeTab] === undefined && defaultTab === "") {
      return;
    }

    const actType = filteredNavigation[activeTab]?.type || defaultTab;

    if (!tabNames.includes(actType)) {
      return;
    }

    setShowFilters(false);

    const tabParams = getTabParams(actType);

    setRequestParams(tabParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, defaultTab]);

  useEffect(() => {
    if (!user?.actTypes?.length || !filteredNavigation.length) {
      return;
    }

    let activeTab = tabUrl || "";

    if (!tabNames.includes(activeTab)) {
      const filterableTabNames = ["convention", "divorce", "digital", "birth"];

      const normalizedFilteredTabNames = user.actTypes.map((tab) => {
        return actTypeNameMap[tab];
      });

      const typesIntersect = filterableTabNames.filter((tab) => {
        return normalizedFilteredTabNames.includes(tab);
      });

      activeTab = typesIntersect[0];
    }

    const tabId = filteredNavigation.findIndex(
      (tab) => tab.url === `/acts/${activeTab}`,
    );

    setActiveTab(tabId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabUrl, user, filteredNavigation]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.actCancelled) {
      fetchActs(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const hasNewButton = filteredNavigation[activeTab]?.isActType;
  const roundButtonStyle = hasNewButton ? "rounded-r-lg" : "rounded-lg";

  const activeTabSlug = tabUrl === "acts" ? defaultTab : tabUrl ?? "";

  return (
    <Layout sidebar={true} backgroundColor="bg-stone-50">
      <PhoneMessage />
      <div className="flex flex-col h-[calc(100%-77px)] px-[54px] pt-[38px] bg-stone-50 relative">
        <Notifications className="absolute top-[50px] md:top-[7px] left-1/2 -translate-x-2/4 w-[90%] md:w-[653px] xl:w-[800px] z-10" />
        <h1 className="mt-24 xl:mt-1 text-xl font-bold text-black max-md:max-w-full">
          {labels.acts}
        </h1>

        <div className="flex flex-col max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
            {/* Tabs */}
            <div className="flex flex-col relative">
              <TabButtons
                tab={activeTab}
                setTab={setActiveTab}
                tabsList={filteredNavigation}
                pillMap={pillMap}
              />
            </div>

            {/* Body of tabs */}
            <div className="flex flex-col bg-white rounded-lg max-md:max-w-full shadow-md">
              <div className="flex flex-col pt-[30px] pb-4 font-medium max-md:px-5 max-md:max-w-full z-10">
                {/* Filters */}
                <div className="flex gap-5 justify-between px-6 w-full mb-[14px] md:flex-wrap max-md:pr-5 max-md:max-w-full">
                  <div className="flex gap-2.5 items-center">
                    <Button
                      onClick={() => setShowFilters(!showFilters)}
                      className="flex items-center my-auto text-sm text-black"
                    >
                      <Icon
                        type="filters"
                        color="#000"
                        className="w-[18px] h-[18px] mr-[12px]"
                      />
                      <p className="hidden md:block">
                        {labels.filtersAndAdvancedSearch}
                      </p>
                    </Button>
                  </div>
                  <div className="flex">
                    {hasNewButton && (
                      <NavLink
                        to={`${filteredNavigation[activeTab].url}/new`}
                        className="flex items-center justify-center px-5 py-[5px] text-sm whitespace-nowrap rounded-l-lg max-md:pr-5 border border-ea-red btn-primary"
                        data-testid="new-act-button"
                      >
                        <Icon
                          type="plus"
                          color="#fff"
                          className="w-6 h-6 mr-[5px]"
                        />
                        <span>{`${filteredNavigation[activeTab].label}`}</span>
                      </NavLink>
                    )}
                    <Button
                      className={`flex justify-between items-center px-5 py-[5px] text-sm whitespace-nowrap ${roundButtonStyle} max-md:pr-5 btn-secondary`}
                      onClick={() => exportActs()}
                      disabled={
                        exportingActs || tableData[actType].length === 0
                      }
                    >
                      {exportingActs && <Spinner className="size-4 mr-2" />}
                      {labels.export}
                    </Button>
                  </div>
                </div>

                <ParamsContext.Provider
                  value={{
                    requestParams,
                    setRequestParams,
                    fetchingActs,
                    setPage,
                    initializing,
                    statuses,
                  }}
                >
                  {showFilters && (
                    <Filters
                      setShowFilters={setShowFilters}
                      activeTabUrl={filteredNavigation[activeTab].url}
                    />
                  )}

                  <Pagination
                    key={activeTab}
                    totalPages={totalPages}
                    setPage={setPage}
                  />

                  {activeTabSlug && tableData[activeTabSlug] ? (
                    <ActsTable
                      key={generateUUID()}
                      data={tableData[activeTabSlug]}
                      activeTab={tabsList?.[activeTab]?.url || ""}
                    />
                  ) : (
                    filteredNavigation && (
                      <div>
                        <div className="w-full h-full pt-[40px] flex justify-center items-center">
                          <Spinner className="size-10" dark={true} />
                        </div>
                      </div>
                    )
                  )}
                </ParamsContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Documents;
