import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@components/ui/Button";
import Spinner from "@components/ui/Spinner";

import Modal from "@ui/Modal";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import {
  selectModalData,
  setActData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

const CancelAct = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const modalData = useSelector(selectModalData);
  const { actType } = useParams();
  const navigate = useNavigate();
  const formValues = modalData?.formValues;
  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const returnPath = queryParams.get("returnPath");
  const backPath = returnPath ?? `/acts/${actType}`;

  // If there is a redirect location in the modal data, use it, otherwise go to the act type
  const quitLocation = modalData?.redirectTo ? modalData.redirectTo : backPath;

  let modalTitle = labels.registerAct;
  let modalDescription = labels.registerActDescription;

  if (location.pathname === "/profile") {
    modalTitle = labels.modification;
    modalDescription = labels.saveModification;
  }

  const handleActCreation = () => {
    if (formValues.name === "") {
      dispatch(setModalData(null));
      dispatch(setPageData(null));
      dispatch(setActData(null));
      dispatch(hideModal());
      navigate(quitLocation);
      return;
    }
    setLoading(true);
    // POST request for creating a new act
    axios
      .post("/api/v1/signbooks", formValues)
      .then((response) => {
        dispatch(setModalData(null));
        dispatch(setPageData(null));
        dispatch(setActData(null));
        dispatch(hideModal());
        navigate(quitLocation);
      })
      .catch((error) => {
        if (Array.isArray(error.response.data)) {
          error.response.data.forEach((error: any) => {
            toast.error(error.message, toastOptionsError);
          });
          return;
        }
        const errorMessage = axiosErrorMessages[error.message] || error.message;
        toast.error(errorMessage, toastOptionsError);
      });
  };

  return (
    <Modal title={modalTitle} description={modalDescription} width={400}>
      <div className="flex items-center justify-between mt-[40px]">
        <Link
          className="btn-secondary rounded-[8px] px-[13px] py-[6px] body-md"
          to={quitLocation}
          onClick={() => {
            dispatch(setModalData(null));
            dispatch(setPageData(null));
            dispatch(setActData(null));
            dispatch(hideModal());
          }}
        >
          {labels.continueWithoutRegistering}
        </Link>
        <Button
          className="btn-primary rounded-[8px] px-[13px] py-[6px] body-md flex items-center justify-center"
          onClick={() => {
            handleActCreation();
          }}
          disabled={loading}
        >
          {loading && <Spinner className="size-4 mr-4" />}
          {labels.save}
        </Button>
      </div>
    </Modal>
  );
};

export default CancelAct;
