import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Button from "@ui/Button";
import CountryField from "@ui/CountryField";
import CustomDatePickerHeader from "@ui/DatepickerCustomHeader";
import FieldDropdown from "@ui/FieldDropdown";
import FileUpload from "@ui/FileUpload";
import Icon from "@ui/Icon";
import Loader from "@ui/Loader";
import Modal from "@ui/Modal";
import PhoneField from "@ui/PhoneField";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { selectModalData, setPostState } from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";
import { hideModal } from "@reducers/modalsSlice";

import { Document } from "@types";

import {
  addQueryParams,
  downloadFile,
  downloadPDF,
  extractFileName,
  formatLabel,
  getMimeType,
  isPastDate,
  validateEmail,
  validateSiren,
} from "@utils";
import { getPhoneCode, getPhoneNumber } from "@utils/phone";
import { nameRegex } from "@utils/regex";

const EditParty = () => {
  const params = useParams();
  const { actType } = params;

  // The following code is used to determine the publicId of the act, depending on how is the route constructed
  const publicId = params.publicId
    ? params.publicId
    : params["*"]?.split("/")[0];

  const data = useSelector(selectModalData);

  const [partyData, setPartyData] = useState<any>({});
  const [initializing, setInitializing] = useState(true);
  // Verify if the party is from contacts book of lawyer because in signatures page we have to fetch the data from contacts book
  const fromContacts = data.fromContacts;
  const [actTitle, setActTitle] = useState<string>("");
  const signatoryCode = fromContacts ? data.party.publicId : data.party.id;
  const dispatch = useDispatch();
  const location = useLocation();
  const [type, setType] = useState(data.party.type || "person");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<Document[] | Record<string, string>[]>(
    data.party.IDdocument || [],
  );
  const [deletingIndices, setDeletingIndices] = useState<number[]>([]);
  const [numberFilesUploaded, setNumberFilesUploaded] = useState(1);
  const [initialValues, setInitialValues] = useState<any>({});
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!fromContacts) {
      return;
    }
    setLoading(true);
    axios
      .get(`/api/v1/contacts/${data.party.publicId}`)
      .then((response) => {
        setPartyData(response.data);
        setLoading(false);
        setInitializing(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  }, [data.party.publicId, fromContacts, data.party.id]);

  useEffect(() => {
    if (!publicId && fromContacts) {
      return;
    }
    setLoading(true);

    if (!fromContacts) {
      axios
        .get(`/api/v1/signbooks/${publicId}`)
        .then((response) => {
          setActTitle(response.data.name);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicId, fromContacts]);

  useEffect(() => {
    if (!publicId || fromContacts) {
      return;
    }
    setLoading(true);
    axios
      .get(`/api/v1/signbooks/${publicId}/signatories/${signatoryCode}`)
      .then((response) => {
        setPartyData(response.data);
        setLoading(false);
        setInitializing(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  }, [signatoryCode, publicId, fromContacts]);

  useEffect(() => {
    setLoading(true);

    if (!publicId) {
      return;
    }

    if (!fromContacts) {
      axios
        .get(
          `/api/v1/signbooks/${publicId}/signatories/${signatoryCode}/identification-documents`,
        )
        .then((response) => {
          const documents = response.data;
          if (documents.length > 0) {
            const newDocuments = documents.map((document: Document) => {
              return {
                id: document.documentUid,
                name:
                  document.documentName?.split("/")[1] || document.documentName,
                size: document.fileSize,
              };
            });

            setFiles(newDocuments);
          }
          setLoading(false);
          setInitializing(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatoryCode, publicId, type]);

  const storeFiles = (
    filesToStore: Document[] | Record<string, string>[],
    existingFiles: Document[] | Record<string, string>[] = files,
  ) => {
    const newFiles = [...existingFiles, ...filesToStore] as Document[];

    setFiles(newFiles);
  };

  const emptyModalFields = Object.keys(partyData).filter(
    (key) => key !== "phoneCode",
  );
  const editMode = 0 !== emptyModalFields.length;

  const createAndDownloadFile = (file: Document | Record<string, string>) => {
    const fileName = file.name;
    const documentId = file.id;
    const fileExtension = fileName?.split(".")?.pop()?.toLowerCase();

    axios
      .get(`api/v1/signbooks/${publicId}/documents/${documentId}/data`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        const fileName = extractFileName(contentDisposition);
        // Determine the MIME type based on the file extension
        if (fileExtension) {
          const mimeType = getMimeType(fileExtension);

          if (mimeType) {
            // Create a Blob from the response data
            const fileBlob = new Blob([response.data], { type: mimeType });

            if (mimeType === "application/pdf") {
              const fileUrl = URL.createObjectURL(fileBlob);
              downloadPDF([fileUrl], fileName);
              return;
            }

            downloadFile(fileBlob, fileName);
          }
        }
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const fieldClass =
    "body-lg border border-[#B4B4B4] py-[4px] rounded-[4px] w-full p-[16px] h-[34px]";
  const fieldClassDisabled = fieldClass.replace(
    "border-[#B4B4B4]",
    "border-[#F5F5F5] bg-white",
  );
  const iconClass =
    "absolute top-1/2 -translate-y-2/4 right-[16px] size-[14px]";

  // Map of field names to their corresponding labels.
  const labelsMap: Record<string, Record<string, string>> = {
    TYPE_NATURAL: {
      lastName: labels.surname,
      firstName: labels.name,
      birthDate: labels.birthDate,
      nationality: labels.nationality,
      email: labels.email,
      phone: labels.phone,
    },
    TYPE_LEGAL: {
      sirenNumber: labels.sirenNumber,
      socialReason: labels.socialReason,
      domiciliation: labels.domiciliation,
      firstName: labels.legalName,
      lastName: labels.legalSurname,
      nationality: labels.nationality,
      email: labels.email,
      phone: labels.phone,
    },
  };

  // Validation schemas for the form.
  const validationSchemaParty = Yup.object().shape({
    firstName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.name))
      .required(formatLabel(labels.requiredField, labels.name))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.name)),
    lastName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.surname))
      .required(formatLabel(labels.requiredField, labels.surname))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.surname)),
    birthDate: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.birthDate))
      .required(formatLabel(labels.requiredField, labels.birthDate))
      .test(
        "is-past-date",
        labels.birthDateMustBeBeforeCurrentDate,
        isPastDate,
      ),
    nationality: Yup.string().required(
      formatLabel(labels.requiredField, labels.nationality),
    ),
    phoneCode: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.phoneCode))
      .required(formatLabel(labels.requiredField, labels.phoneCode)),
    IDdocument: Yup.array().required(
      formatLabel(labels.requiredField, labels.addNewID),
    ),
    inPresence: Yup.boolean(),
  });

  const validationSchemaCompany = Yup.object().shape({
    phoneCode: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.phoneCode))
      .required(formatLabel(labels.requiredField, labels.phoneCode)),
    socialReason: Yup.string()
      .max(64, formatLabel(labels.maxCharLabel, "64"))
      .trim(formatLabel(labels.requiredField, labels.socialReason))
      .required(formatLabel(labels.requiredField, labels.socialReason)),
    firstName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.legalName))
      .required(formatLabel(labels.requiredField, labels.legalName))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.legalName)),
    lastName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.legalSurname))
      .required(formatLabel(labels.requiredField, labels.legalSurname))
      .min(3, formatLabel(labels.minCharLabel, "3"))
      .max(50, formatLabel(labels.maxCharLabel, "50"))
      .matches(
        nameRegex,
        formatLabel(labels.compliantField, labels.legalSurname),
      ),
    nationality: Yup.string().required(
      formatLabel(labels.requiredField, labels.nationality),
    ),
    inPresence: Yup.boolean(),
  });

  const getValidationSchema = () => {
    return "TYPE_NATURAL" === type
      ? validationSchemaParty
      : validationSchemaCompany;
  };

  // Whether the entity is sealed or not
  const isSealed: boolean = partyData.isLocked;

  // List of read-only fields if the entity is sealed.
  const readOnlyFieldsIfSealed =
    "TYPE_NATURAL" === type
      ? ["lastName", "firstName", "birthDate", "nationality"]
      : ["sirenNumber", "socialReason", "firstName", "lastName", "nationality"];

  // Values that we can't edit in the modal if sealed for both physical and company.
  // const formattedBirthDate = new Date(data.party.birthDate);

  // Function to format the date before sending to BE or display to be in 'DD/MM/YYYY' format
  const formatDateBirth = (dateStr: string) => {
    if (dateStr) {
      if (dateStr.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
        const [year, month, day] = dateStr.split("/");
        return `${day}/${month}/${year}`;
      } else {
        // If already in 'DD/MM/YYYY' format or invalid format, return as-is
        return dateStr;
      }
    }
    return;
  };

  const deleteUploadedFile = (
    documentId: string,
    index: number,
    formik: any,
  ) => {
    setLoading(true);
    setDeletingIndices((prev) => [...prev, index]);
    axios
      .delete(
        `api/v1/signbooks/${publicId}/signatories/${signatoryCode}/identification-documents/${documentId}`,
      )
      .then(() => {
        const newFiles = (files as Document[]).filter((_, i) => i !== index);

        setFiles(newFiles);

        if (0 === newFiles.length) {
          formik.setFieldValue("IDdocument", []);
        }
        setLoading(false);
        setDeletingIndices((prev) => prev.filter((i) => i !== index));
      })
      .catch((error) => {
        setLoading(false);
        setDeletingIndices((prev) => prev.filter((i) => i !== index));
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const readOnlyValuesIfSealed = {
    lastName: partyData.lastName || "",
    firstName: partyData.firstName || "",
    nationality: partyData.nationality,
    birthDate: formatDateBirth(partyData.birthDate),
    sirenNumber: partyData.siren || "",
    socialReason: partyData.companyName || "",
  };

  const phoneNumber = partyData.mobileNumber || "";
  const phoneCountryCode = partyData.mobilePhoneCountryCode || "FR";

  const splitPhoneNumber = getPhoneNumber(phoneNumber, phoneCountryCode);
  const splitPhoneCode = getPhoneCode(phoneNumber, phoneCountryCode);

  // Values that we can edit in the modal.
  const editableValues = {
    email: partyData.email || "",
    commonName: partyData.commonName || "",
    phone: splitPhoneNumber || "",
    phoneCode: splitPhoneCode || "+33",
    phoneCountryCode: phoneCountryCode,
    IDdocument: files || "",
    inPresence: partyData.inPresence,
  };

  // Combine both values for initializing Formik.
  useEffect(() => {
    if (!partyData) {
      return;
    }
    const initialData = { ...readOnlyValuesIfSealed, ...editableValues };
    setInitialValues(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyData]);

  const titleLabel = actType === "convention" ? labels.client : labels.party;
  const descriptionText =
    actType === "convention" ? labels.clientSealed : labels.partySealed;
  const modalOverflowStyle = fromContacts ? "" : "overflow-y-auto";
  const sealedPartyMargin = !isSealed ? "mr-5" : "";
  return (
    <Modal
      title={titleLabel}
      description={isSealed ? descriptionText : ""}
      width={377}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
        onSubmit={(values) => {
          const updatedPartyData = {
            ...data.party,
            ...values,
            type: type,
          };

          if (!editMode) {
            updatedPartyData.icon =
              type === "person" ? "profile-male" : "apartment";
          }

          updatedPartyData.IDdocument =
            0 !== files.length
              ? files.map((file) => {
                  const { name } = file;

                  return {
                    name,
                  };
                })
              : [];

          const updatedData = {
            ...data,
            party: updatedPartyData,
          };

          if (fromContacts) {
            axios
              .put(`/api/v1/contacts/${data.party.publicId}`, {
                publicId: data.party.publicId,
                firstName: updatedData.party.firstName,
                lastName: updatedData.party.lastName,
                nationality: updatedData.party.nationality,
                siren: updatedData.party.sirenNumber,
                companyName: updatedData.party.socialReason,
                birthDate: formatDateBirth(updatedData.party.birthDate),
                email: updatedData.party.email,
                mobileNumber: `${updatedData.party.phoneCode}${updatedData.party.phone}`,
                mobilePhoneCountryCode: updatedData.party.phoneCountryCode,
                personType:
                  updatedData.party.type === "TYPE_NATURAL"
                    ? "person"
                    : "company",
                inPresence: updatedData.party.signatureInPresence,
                commonName: updatedData.party.commonName,
              })
              .then((response) => {
                dispatch(setPostState(true));
              })
              .catch((error) => {
                toast.error(
                  axiosErrorMessages[error.message],
                  toastOptionsError,
                );
              });
          } else {
            axios
              .put(
                `/api/v1/signbooks/${publicId}/signatories/${data.party.id}`,
                {
                  signatoryId: data.party.id,
                  firstName: updatedData.party.firstName,
                  lastName: updatedData.party.lastName,
                  nationality: updatedData.party.nationality,
                  siren: updatedData.party.sirenNumber,
                  companyName: updatedData.party.socialReason,
                  birthDate: formatDateBirth(updatedData.party.birthDate),
                  email: updatedData.party.email,
                  commonName: updatedData.party.commonName,
                  mobileNumber: `${updatedData.party.phoneCode}${updatedData.party.phone}`,
                  mobilePhoneCountryCode: updatedData.party.phoneCountryCode,
                  advisor: data.party.advisor,
                  linkedTo: data.party.linkedTo,
                  inPresence: updatedData.party.inPresence,
                  personType:
                    data.party.type === "TYPE_NATURAL" ? "person" : "company",
                },
              )
              .then((response) => {
                dispatch(setPostState(true));
              })
              .catch((error) => {
                toast.error(
                  axiosErrorMessages[error.message],
                  toastOptionsError,
                );
              });
          }

          dispatch(hideModal());
        }}
      >
        {(formik) => {
          const fieldClassFinal = isSealed ? fieldClassDisabled : fieldClass;
          return (
            <Form>
              <div
                className={`scrollbar-outside max-h-[660px] my-8 ${modalOverflowStyle}`}
              >
                {!initializing ? (
                  <>
                    <div className="party-type-dropdown relative">
                      <Field name="type" type="hidden" />
                      <FieldDropdown
                        arrowPosition="right"
                        initialValue={type}
                        options={[
                          {
                            label: labels.physicalPerson,
                            value: "TYPE_NATURAL",
                          },
                          { label: labels.company, value: "TYPE_LEGAL" },
                        ]}
                        onChange={(value) => {
                          setType(value);
                        }}
                        disabled={editMode}
                      />
                      {editMode && (
                        <Icon
                          type="check"
                          className={iconClass}
                          color="#00C45A"
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <Loader
                    style={{
                      height: "34px",
                    }}
                  />
                )}
                {readOnlyFieldsIfSealed.map((value, key) => {
                  const labelText = labelsMap[type][value];

                  if (!labelText) {
                    return null;
                  }

                  const isDatepicker = "birthDate" === value;
                  const isSirenNumber = "sirenNumber" === value;
                  const isNationality = "nationality" === value;

                  return (
                    <div key={key}>
                      {labelText && (
                        <p className="my-2">
                          <label
                            className="body-sm text-[#8F8F8F]"
                            htmlFor={value}
                          >
                            {labelText}
                          </label>
                        </p>
                      )}
                      {!initializing ? (
                        <>
                          <div className="relative [&>.react-datepicker-wrapper]:block">
                            {isDatepicker ? (
                              <Field name={value}>
                                {({ field }: any) => (
                                  <DatePicker
                                    {...field}
                                    renderCustomHeader={(props) => (
                                      <CustomDatePickerHeader {...props} />
                                    )}
                                    id={value}
                                    className={`${fieldClassFinal} bg-white w-full`}
                                    disabled={isSealed}
                                    onChange={(date) => {
                                      if (date) {
                                        const formattedDate = new Date(
                                          date,
                                        ).toLocaleDateString("fr-FR");
                                        formik.setFieldValue(
                                          value,
                                          formattedDate,
                                        );
                                      }
                                    }}
                                  />
                                )}
                              </Field>
                            ) : isNationality ? (
                              <Field name={value}>
                                {({ field }: any) => (
                                  <CountryField
                                    {...field}
                                    nationality="nationality"
                                    setFieldValue={formik.setFieldValue}
                                    disabled={isSealed}
                                  />
                                )}
                              </Field>
                            ) : (
                              <Field
                                id={value}
                                name={value}
                                type="text"
                                className={`${fieldClassFinal} bg-white`}
                                disabled={isSealed}
                                validate={
                                  isSirenNumber ? validateSiren : undefined
                                }
                              />
                            )}

                            {isSealed && (
                              <Icon
                                type="check"
                                className={iconClass}
                                color="#00C45A"
                              />
                            )}
                            <ErrorMessage name={value}>
                              {(msg) => (
                                <span className="text-[10px] max-w-[293px] text-ea-red">
                                  {msg}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                        </>
                      ) : (
                        <Loader
                          style={{
                            height: "30px",
                          }}
                        />
                      )}
                    </div>
                  );
                })}
                {/* commonName */}
                {actType === "divorce" &&
                  (!initializing ? (
                    <>
                      <p className="my-2">
                        <label
                          className="body-sm text-[#8F8F8F]"
                          htmlFor="commonName"
                        >
                          {labels.commonName}
                        </label>
                      </p>
                      <div className="relative">
                        <Field
                          id="commonName"
                          name="commonName"
                          type="text"
                          className={fieldClass}
                          disabled={isSealed}
                        />
                        <ErrorMessage name="commonName">
                          {(msg) => (
                            <span className="text-[10px] max-w-[293px] text-ea-red">
                              {msg}
                            </span>
                          )}
                        </ErrorMessage>
                      </div>
                    </>
                  ) : (
                    <Loader
                      style={{
                        height: "30px",
                      }}
                    />
                  ))}
                {/* Email */}
                <p className="my-2">
                  <label className="body-sm text-[#8F8F8F]" htmlFor="email">
                    {labels.email}
                  </label>
                </p>
                {!initializing ? (
                  <>
                    <div className="relative">
                      <Field
                        id="email"
                        name="email"
                        type="text"
                        className={fieldClass}
                        validate={validateEmail}
                      />
                      <ErrorMessage name="email">
                        {(msg) => (
                          <span className="text-[10px] max-w-[293px] text-ea-red">
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                    </div>
                  </>
                ) : (
                  <Loader
                    style={{
                      height: "30px",
                    }}
                  />
                )}
                {/* Phone */}
                <p className="my-2">
                  <label className="body-sm text-[#8F8F8F]" htmlFor="phone">
                    {labels.phone}
                  </label>
                </p>
                {!initializing ? (
                  <>
                    <PhoneField
                      phoneCodeName="phoneCode"
                      setFieldValue={formik.setFieldValue}
                      invertedDropdown={true}
                    />
                  </>
                ) : (
                  <Loader
                    style={{
                      height: "30px",
                    }}
                  />
                )}

                {!initializing ? (
                  <div className="relative my-3">
                    <label className="flex relative body-md text-center items-center custom-checkbox-container">
                      <Field
                        type="checkbox"
                        name="inPresence"
                        id="inPresence"
                        className="custom-checkbox"
                      />
                      {labels.signatureInPresence}
                    </label>
                  </div>
                ) : (
                  <Loader
                    style={{
                      height: "30px",
                    }}
                  />
                )}
                {!fromContacts && (
                  <>
                    {/* ID Document */}
                    {actType !== "convention" && (
                      <>
                        {isSealed && 0 === files.length ? null : (
                          <p className="my-2">
                            <label className="body-sm text-[#8F8F8F]">
                              {isSealed ? labels.IDdocuments : labels.addNewID}
                            </label>
                          </p>
                        )}
                        {!initializing ? (
                          <>
                            <Field name="IDdocument">
                              {() => (
                                <div className="modal-file-upload">
                                  <FileUpload
                                    files={files}
                                    setFiles={storeFiles}
                                    minimized={true}
                                    isLocked={isSealed}
                                    url={`/api/v1/signbooks/${publicId}/signatories/${signatoryCode}/identification-documents`}
                                    acceptedFileTypes={{
                                      "image/jpeg": [],
                                      "image/png": [],
                                      "image/bmp": [],
                                      "application/pdf": [],
                                    }}
                                    onChange={(files: Document[]) => {
                                      setNumberFilesUploaded(files.length);
                                      const IDfiles =
                                        files.length !== 0
                                          ? files.map((file) => {
                                              const { name } = file;
                                              return { name };
                                            })
                                          : [];

                                      formik.setFieldValue(
                                        "IDdocument",
                                        IDfiles,
                                      );
                                    }}
                                    setLoading={setLoading}
                                  />
                                  {!initializing ? (
                                    <>
                                      {files.map((file, index) => {
                                        const isDeleting =
                                          deletingIndices.includes(index);
                                        if (isDeleting) {
                                          return (
                                            <Loader
                                              style={{ height: "30px" }}
                                            />
                                          );
                                        }
                                        return (
                                          <div
                                            key={index}
                                            className="flex items-center py-1 px-2 border border-black border-dashed rounded-md h-[34px] w-full mx-auto relative mt-[10px]"
                                          >
                                            <span className="body-sm truncate max-w-[165px]">
                                              {file.path || file.name}
                                            </span>
                                            <div className="absolute top-0 right-0 px-2 h-full flex justify-center items-center">
                                              <Link
                                                className="mr-5"
                                                to={addQueryParams(
                                                  `/acts/birth/new/documents/preview`,
                                                  {
                                                    documentId:
                                                      file.id || "single",
                                                    returnPath:
                                                      location.pathname,
                                                    documentName: file.name,
                                                    actId: publicId,
                                                    actTitle: actTitle,
                                                    lawyer: user?.id
                                                      ? "true"
                                                      : "",
                                                  },
                                                )}
                                                onClick={() => {
                                                  dispatch(hideModal());
                                                }}
                                              >
                                                <Icon
                                                  type="preview"
                                                  className="size-[18px]"
                                                />
                                              </Link>
                                              <Button
                                                type="button"
                                                className={sealedPartyMargin}
                                                onClick={() => {
                                                  createAndDownloadFile(file);
                                                }}
                                              >
                                                <Icon
                                                  type="download"
                                                  className="size-[18px]"
                                                />
                                              </Button>
                                              {!isSealed && (
                                                <Button
                                                  type="button"
                                                  onClick={() => {
                                                    deleteUploadedFile(
                                                      file.id,
                                                      index,
                                                      formik,
                                                    );
                                                  }}
                                                >
                                                  <Icon
                                                    type="trash"
                                                    className="size-[18px]"
                                                  />
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                      {/* Display as much loaders as number of uploaded files */}
                                      {loading &&
                                        deletingIndices.length === 0 &&
                                        [...Array(numberFilesUploaded)].map(
                                          (_, index) => (
                                            <Loader
                                              key={`loading-${index}`}
                                              style={{ height: "30px" }}
                                            />
                                          ),
                                        )}
                                    </>
                                  ) : (
                                    <Loader style={{ height: "30px" }} />
                                  )}
                                </div>
                              )}
                            </Field>
                            <ErrorMessage name="IDdocument">
                              {(msg) => (
                                <p className="text-[10px] max-w-[293px] text-ea-red mt-2">
                                  {msg}
                                </p>
                              )}
                            </ErrorMessage>
                          </>
                        ) : (
                          <Loader style={{ height: "90px" }} />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="flex justify-between">
                <Button
                  className="btn-secondary flex justify-start items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
                  onClick={() => dispatch(hideModal())}
                >
                  {labels.cancel}
                </Button>
                <Button
                  className="btn btn-secondary-emphasize flex justify-end items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
                  type="submit"
                >
                  {labels.save}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditParty;
