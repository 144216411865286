import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, toastOptionsError } from "@constants";

import { extractFileName } from "@utils";

const getDocumentsBySignBookApi = (signBookId: string) => {
  return axios
    .get(`/api/v1/signbooks/${signBookId}/documents/zip`, {
      responseType: "blob",
    })
    .then((response) => {
      const contentDisposition = response.headers["content-disposition"];
      const filename = extractFileName(contentDisposition);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(axiosErrorMessages[errorMessage], toastOptionsError);
    });
};

export { getDocumentsBySignBookApi };
